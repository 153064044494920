import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "button",
  role: "button",
  class: "z-10 w-full focus:outline-none"
}
const _hoisted_2 = {
  key: 1,
  ref: "default"
}
const _hoisted_3 = { class: "sticky bottom-0 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "main",
    class: "relative inline-flex focus:outline-none",
    onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.close()), ["esc"]))
  }, [
    (_ctx.$slots.button)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "button")
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ], 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-0 flex flex-col w-full h-full transition duration-100 ease-in-out", _ctx.isOpen ? 'z-50 opacity-100 visible' : 'z-0 opacity-0 w-0 h-0 invisible']),
      style: _normalizeStyle(_ctx.style)
    }, [
      _createElementVNode("div", {
        ref: "menu",
        "data-dusk": "popover-menu",
        style: _normalizeStyle(_ctx.menuStyle),
        class: _normalizeClass(["absolute top-0 flex overflow-auto scrollbar-dark flex-col border rounded-lg shadow-lg", [
          _ctx.wrap ? '' : 'whitespace-no-wrap',
          _ctx.dark ? 'bg-gray-900 border-gray-900' : 'bg-white border-gray-300',
          _ctx.isOpen ? 'visible opacity-100 z-50' : 'invisible opacity-0',
          _ctx.position === 'left' ? 'right-0' : 'left-0',
          { 'p-2': _ctx.hasPadding },
        ]])
      }, [
        _renderSlot(_ctx.$slots, "menu"),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "footer")
        ])
      ], 6)
    ], 6)
  ], 544))
}