import { NavigationGuardNext, RouteLocationNormalized, RouteRecord } from 'vue-router';
import * as Sentry from '@sentry/browser';
import tokenService from '@/services/token.service';
import { i18n } from '@/i18n/i18n';
import { useHistoryStore } from '@/stores/history.store';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useUiStore } from '@/stores/ui.store';
import { useNotificationStore } from '@/stores/notification.store';

export const beforeEach = async (
  routeTo: RouteLocationNormalized,
  routeFrom: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const historyStore = useHistoryStore();
  const sessionStore = useSessionStore();
  const patientStore = usePatientStore();
  const notificationStore = useNotificationStore();
  const uiStore = useUiStore();
  if (routeFrom.name !== routeTo.name && routeFrom.name) {
    historyStore.from = routeFrom.name;
  }
  historyStore.to = routeTo.name ?? 'patient-list';

  if (isChrome() || isFirefox()) {
    if (routeTo.meta.authRequired === false && routeTo.name !== 'login') {
      return next();
    }

    // All routes require authentication by default - check if authentication is not required
    const authRequired = !routeTo.matched.some((route: RouteRecord) => !route.meta.authRequired);

    // Get Authenticated status
    const authenticated = !!tokenService.getToken();

    // Redirect to login if auth required and user not authenticated
    if (authRequired && !authenticated) {
      return next({ name: 'login' });
    }

    // user is authenticated
    if (authenticated) {
      // if no current User in store, we run fetchCurrentUser;
      if (!sessionStore.currentUser) {
        try {
          uiStore.loading = true;
          await sessionStore.fetchCurrentUser();
          await sessionStore.fetchAdminCustomer();
          await patientStore.fetchExternalPatientReferenceTypes();
        } catch (e) {
          tokenService.removeToken();
          return next({ name: 'login' });
        } finally {
          uiStore.loading = false;
        }
      }

      // setup sentry user context
      const currentUser = sessionStore.currentUser;
      if (currentUser) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: currentUser.id,
            email: currentUser.email
          });
        });
      }
      if (!routeTo.meta?.isAdmin && routeTo.params.organisationId) {
        if (sessionStore.getOrganisation(routeTo.params.organisationId)) {
          sessionStore.setCurrentOrganisationId(routeTo.params.organisationId);
        } else {
          return next({ name: '401' });
        }
      } else if (routeTo.query?.customer) {
        sessionStore.setCurrentOrganisationIdForCustomer(routeTo.query?.customer);
      }

      const hasCurrentOrganisation =
        typeof localStorage.getItem('organisationId') !== 'undefined' &&
        localStorage.getItem('organisationId') !== null &&
        localStorage.getItem('organisationId') !== 'null';

      // 1. Check if user has agreed the current customer's terms.
      if (
        routeTo.name !== 'user-agreement' &&
        currentUser &&
        sessionStore.policiesToAgreeTo.length
      ) {
        return next({ name: 'user-agreement' });
      }

      // 2. User has agreed to terms
      if (!sessionStore.policiesToAgreeTo.length) {
        // 2.1 If user is not admin, they are forbidden from accessing an admin route
        if (routeTo.meta?.isAdmin && currentUser && !currentUser.is_admin) {
          notificationStore.snackbar = {
            label: i18n.global.t('platform.error.403'),
            autoDismiss: true,
            dismissAfter: 6000,
            color: 'danger',
            icon: 'warning'
          };

          return next({ path: routeFrom.path || '/' });
        }

        // 2.2 If user is admin, log in and redirect to Domain Admin
        if (routeTo.meta?.isAdmin && currentUser?.is_admin) {
          return next();
        }
        if (
          currentUser?.is_admin &&
          (routeFrom.name === 'login' || routeFrom.name === 'user-agreement') &&
          !currentUser?.enforce_password_reset_at
        ) {
          return routeTo.name === 'domain-admin' ? next() : next({ name: 'domain-admin' });
        }

        // 2.3 If a user has at least one organisation
        if (hasCurrentOrganisation) {
          const permissionList = sessionStore.permissions;

          // 2.3.1 If user has no permission to see Patient list but can see Participant list, redirect to Participant list
          if (
            routeTo.name === 'patient-list' &&
            !permissionList.includes('patient:read') &&
            permissionList.includes('anonymous-participant:read')
          ) {
            return next({ name: 'participant-list', params: { organisationId: routeTo.params?.organisationId } });
          }

          // 2.3.2 If user has no permission to see Participant list but can see Patient list, redirect to Patient list
          if (
            routeTo.name === 'participant-list' &&
            permissionList.includes('patient:read') &&
            !permissionList.includes('anonymous-participant:read')
          ) {
            return next({ name: 'patient-list', params: { organisationId: routeTo.params?.organisationId } });
          }

          // 2.3.3 If user has no permission to see Participant nor Patient list, redirect to Profile
          if (
            (routeTo.name === 'participant-list' && !permissionList.includes('anonymous-participant:read')) ||
            (routeTo.name === 'patient-list' && !permissionList.includes('patient:read'))
          ) {
            return next({ name: 'settings' });
          }

          // 2.3.4 Check permissions: if permission required and user does not have permission, redirect to 403.
          if (routeTo.meta?.permission && !permissionList.includes(routeTo.meta.permission)) {
            notificationStore.snackbar = {
              label: i18n.global.t('platform.error.403'),
              autoDismiss: true,
              dismissAfter: 6000,
              color: 'danger',
              icon: 'warning'
            };

            return next({ path: routeFrom.path || '/' });
          }

          // 2.3.5 If user is authenticated but route is a login, cancel route transition
          if (routeTo.meta?.loginPage) {
            if (permissionList.includes('anonymous-participant:read')) {
              return next({ name: 'participant-list', params: { organisationId: sessionStore.currentOrganisationId } });
            }
            return next({ name: 'patient-list', params: { organisationId: sessionStore.currentOrganisationId } });
          }
        }

        // 2.4 User is always allowed to access their profile and account settings
        if (routeTo.meta?.isUserSettings) {
          return next();
        }

        // 2.5 If user has no organisation, go to settings
        if (!hasCurrentOrganisation) {
          return next({ name: 'settings' });
        }
      }
    }
  } else if (routeTo.name !== 'unsupported-browser') {
    return next({ name: 'unsupported-browser' });
  }

  return next();
};
declare const InstallTrigger: any;

const isFirefox = () => typeof InstallTrigger !== 'undefined';

const isChrome = () => {
  // @ts-ignore
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');
  const isHeadlessChrome = winNav.userAgent.match('HeadlessChrome');

  return !!(
    isIOSChrome ||
    isHeadlessChrome ||
    (isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && !isOpera && !isIEedge)
  );
};
