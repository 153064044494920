import {
  Encounter,
  EncounterTypeName,
  KUnit,
  SlitLampCondition,
  SlitLampConditionGrading,
  VaUnit
} from '@/custom/menicon/models';
import { IOption, MeasurementsValue, TopographyValue } from '@/lib';
import { vaOptions } from '@/custom/menicon/constants';
import { i18n } from '@/i18n/i18n';

interface EncounterVARefractionData {
  sphere?: string;
  cylinder?: string;
  axis?: string;
  visual_acuity?: string;
  addition?: string;
  axial_length?: string;
}

export interface EncounterData {
  distanceVa: {
    is_va_satisfactory?: boolean;
    l_is_va_satisfactory?: boolean;
    r_is_va_satisfactory?: boolean;
    r_distance_visual_acuity?: string;
    l_distance_visual_acuity?: string;
    combined_distance_visual_acuity?: string;
  };
  vaRefraction: {
    right: EncounterVARefractionData;
    left: EncounterVARefractionData;
    both: EncounterVARefractionData;
  };
  myopiaPrognosis: {
    rSphere: string;
    lSphere: string;
    rCylinder: string;
    lCylinder: string;
    rAxialLength: string;
    lAxialLength: string;
  };
  topographyScans: {
    right: TopographyValue;
    left: TopographyValue;
  };
  measurements: {
    right: MeasurementsValue;
    left: MeasurementsValue;
  };
  lensOrdering: {
    rSphere: number;
    lSphere: number;
    rCylinder: number;
    lCylinder: number;
    rAddition: number;
    lAddition: number;
    rAxis: number;
    lAxis: number;
    rFlatK: number;
    lFlatK: number;
    rSteepK: number;
    lSteepK: number;
    rFlatKAxis: number;
    lFlatKAxis: number;
    rHvid: number;
    lHvid: number;
    rMlsId: string;
    lMlsId: string;
    kUnit: KUnit;
  };
  differenceMaps: {
    r_is_expected_treatment_zone?: boolean;
    l_is_expected_treatment_zone?: boolean;
    r_is_expected_centration?: boolean;
    l_is_expected_centration?: boolean;
    r_pattern?: 'a' | 'b';
    l_pattern?: 'a' | 'b';
  };
  preFittingSlitLamp: {
    conditions: Encounter['slit_lamp_conditions'];
    isNoneChecked: boolean;
    details: string;
  };
  slitLamp: {
    conditions: Encounter['slit_lamp_conditions'];
    isNoneChecked: boolean;
    details: string;
  };
}

export const getSlitLampConfig = (encounterType: EncounterTypeName): Record<SlitLampCondition, boolean> => {
  const defaultConfig = {
    [SlitLampCondition.CORNEAL_STAINING]: true,
    [SlitLampCondition.CORNEAL_INFILTRATES]: true,
    [SlitLampCondition.PAPILLARY_REACTION]: true,
    [SlitLampCondition.CONJUNCTIVAL_HYPERMIA]: true,
    [SlitLampCondition.CORNEAL_NEOVASCULARISATION]: true
  };
  switch (encounterType) {
  case EncounterTypeName.BLOOM_NIGHT_COLLECTION:
  case EncounterTypeName.BLOOM_DAY_LENS_COLLECTION:
    return {
      [SlitLampCondition.CORNEAL_STAINING]: true,
      [SlitLampCondition.CORNEAL_INFILTRATES]: false,
      [SlitLampCondition.PAPILLARY_REACTION]: false,
      [SlitLampCondition.CONJUNCTIVAL_HYPERMIA]: false,
      [SlitLampCondition.CORNEAL_NEOVASCULARISATION]: false
    };
  case EncounterTypeName.BLOOM_NIGHT_1_NIGHT_FOLLOW_UP:
  case EncounterTypeName.BLOOM_NIGHT_1_WEEK_FOLLOW_UP:
  case EncounterTypeName.BLOOM_DAY_1_WEEK_LENS_EFFICACY_ASSESSMENT:
    return {
      ...defaultConfig,
      [SlitLampCondition.PAPILLARY_REACTION]: false,
      [SlitLampCondition.CORNEAL_NEOVASCULARISATION]: false
    };
  case EncounterTypeName.BLOOM_NIGHT_3_WEEK_LENS_EFFICACY_ASSESSMENT:
    return {
      ...defaultConfig,
      [SlitLampCondition.PAPILLARY_REACTION]: false,
      [SlitLampCondition.CONJUNCTIVAL_HYPERMIA]: false,
      [SlitLampCondition.CORNEAL_NEOVASCULARISATION]: false
    };
  case EncounterTypeName.BLOOM_NIGHT_3_OR_9_MONTHS_ASSESSMENT:
  case EncounterTypeName.BLOOM_DAY_3_OR_9_MONTHS_HEALTH_ASSESSMENT:
    return {
      ...defaultConfig,
      [SlitLampCondition.CONJUNCTIVAL_HYPERMIA]: false,
      [SlitLampCondition.CORNEAL_NEOVASCULARISATION]: false
    };
  case EncounterTypeName.INITIAL_MEASUREMENT:
  case EncounterTypeName.BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT:
  default:
    return defaultConfig;
  }
};

const getKValue = (value: number, convert: boolean): number => convert ? 337.5 / value : value;

export const getEncounterData = (encounter?: Encounter, kUnit: KUnit = encounter.k_unit): EncounterData => ({
  distanceVa: {
    is_va_satisfactory: encounter?.is_va_satisfactory,
    r_distance_visual_acuity: encounter?.r_distance_visual_acuity,
    l_distance_visual_acuity: encounter?.l_distance_visual_acuity,
    combined_distance_visual_acuity: encounter?.combined_distance_visual_acuity,
    l_is_va_satisfactory: encounter?.l_is_va_satisfactory,
    r_is_va_satisfactory: encounter?.r_is_va_satisfactory
  },
  vaRefraction: {
    right: {
      sphere: encounter?.r_sphere?.toString(),
      cylinder: encounter?.r_cylinder?.toString(),
      axis: encounter?.r_axis?.toString(),
      visual_acuity: encounter?.r_visual_acuity,
      addition: encounter?.r_addition?.toString(),
      axial_length: encounter?.r_axial_length?.toString()
    },
    left: {
      sphere: encounter?.l_sphere?.toString(),
      cylinder: encounter?.l_cylinder?.toString(),
      axis: encounter?.l_axis?.toString(),
      visual_acuity: encounter?.l_visual_acuity,
      addition: encounter?.l_addition?.toString(),
      axial_length: encounter?.l_axial_length?.toString()
    },
    both: {
      visual_acuity: encounter?.combined_visual_acuity
    }
  },
  myopiaPrognosis: {
    rSphere: encounter?.r_sphere?.toString() || '',
    lSphere: encounter?.l_sphere?.toString() || '',
    rCylinder: encounter?.r_cylinder?.toString() || '',
    lCylinder: encounter?.l_cylinder?.toString() || '',
    rAxialLength: encounter?.r_axial_length?.toString() || '',
    lAxialLength: encounter?.l_axial_length?.toString() || ''
  },
  topographyScans: {
    right: {
      mlsId: encounter?.r_mls_id || '',
      topographer: '',
      hvid: encounter?.r_hvid?.toString() || null,
      flat_k: getKValue(+(encounter?.r_flat_k || 0), encounter.k_unit !== kUnit),
      steep_k: getKValue(+(encounter?.r_steep_k || 0), encounter.k_unit !== kUnit),
      flat_k_axis: +(encounter?.r_flat_k_axis || 0)
    },
    left: {
      mlsId: encounter?.l_mls_id || '',
      topographer: '',
      hvid: encounter?.l_hvid?.toString() || null,
      flat_k: getKValue(+(encounter?.l_flat_k || 0), encounter.k_unit !== kUnit),
      steep_k: getKValue(+(encounter?.l_steep_k || 0), encounter.k_unit !== kUnit),
      flat_k_axis: +(encounter?.l_flat_k_axis || 0)
    }
  },
  measurements: {
    right: {
      hvid: encounter?.r_hvid?.toString() || null,
      flat_k: encounter?.r_flat_k ? getKValue(+encounter.r_flat_k, encounter.k_unit !== kUnit).toString() : '',
      steep_k: encounter?.r_steep_k ? getKValue(+encounter.r_steep_k, encounter.k_unit !== kUnit).toString() : '',
      flat_k_axis: encounter?.r_flat_k_axis?.toString() || ''
    },
    left: {
      hvid: encounter?.l_hvid?.toString() || null,
      flat_k: encounter?.l_flat_k ? getKValue(+encounter.l_flat_k, encounter.k_unit !== kUnit).toString() : '',
      steep_k: encounter?.l_steep_k ? getKValue(+encounter.l_steep_k, encounter.k_unit !== kUnit).toString() : '',
      flat_k_axis: encounter?.l_flat_k_axis?.toString() || ''
    }
  },
  lensOrdering: {
    rSphere: +(encounter?.r_sphere || 0),
    lSphere: +(encounter?.l_sphere || 0),
    rCylinder: +(encounter?.r_cylinder || 0),
    lCylinder: +(encounter?.l_cylinder || 0),
    rAddition: +(encounter?.r_addition || 0),
    lAddition: +(encounter?.l_addition || 0),
    rAxis: +(encounter?.r_axis || 0),
    lAxis: +(encounter?.l_axis || 0),
    rFlatK: getKValue(+(encounter?.r_flat_k || 0), encounter.k_unit !== kUnit),
    lFlatK: getKValue(+(encounter?.l_flat_k || 0), encounter.k_unit !== kUnit),
    rSteepK: getKValue(+(encounter?.r_steep_k || 0), encounter.k_unit !== kUnit),
    lSteepK: getKValue(+(encounter?.l_steep_k || 0), encounter.k_unit !== kUnit),
    rFlatKAxis: +(encounter?.r_flat_k_axis || 0),
    lFlatKAxis: +(encounter?.l_flat_k_axis || 0),
    rHvid: +(encounter?.r_hvid || 0),
    lHvid: +(encounter?.l_hvid || 0),
    rMlsId: encounter?.r_mls_id || '',
    lMlsId: encounter?.l_mls_id || '',
    kUnit: kUnit || KUnit.MM
  },
  differenceMaps: {
    r_is_expected_treatment_zone: encounter?.r_is_expected_treatment_zone,
    l_is_expected_treatment_zone: encounter?.l_is_expected_treatment_zone,
    r_is_expected_centration: encounter?.r_is_expected_centration,
    l_is_expected_centration: encounter?.l_is_expected_centration,
    r_pattern: encounter?.r_pattern,
    l_pattern: encounter?.l_pattern
  },
  preFittingSlitLamp: {
    conditions: encounter?.pre_fitting_slit_lamp_conditions ||
      {} as { [key in SlitLampCondition]?: SlitLampConditionGrading },
    isNoneChecked: encounter?.pre_fitting_is_slit_lamp_condition_none || false,
    details: encounter?.pre_fitting_slit_lamp_details || ''
  },
  slitLamp: {
    conditions: encounter?.slit_lamp_conditions || {} as { [key in SlitLampCondition]?: SlitLampConditionGrading },
    isNoneChecked: encounter?.is_slit_lamp_condition_none || false,
    details: encounter?.slit_lamp_details || ''
  }
});

export const getVaOptions = (vaUnit: VaUnit, locale = i18n.global.locale.value): IOption[] => [
  ...vaOptions[vaUnit].map((value: string) => ({
    value,
    label: i18n.global.t('platform.eyes.va.value', {
      value,
      unit: i18n.global.t(`platform.eyes.va.units.${vaUnit}`, [], { locale })
    }, { locale })
  })),
  {
    value: 'Count Fingers',
    label: i18n.global.t('platform.eyes.va.count-fingers', [], { locale }) as string
  },
  {
    value: 'Hand Motion Only',
    label: i18n.global.t('platform.eyes.va.hand-motion-only', [], { locale }) as string
  },
  {
    value: 'Light Perception',
    label: i18n.global.t('platform.eyes.va.light-perception', [], { locale }) as string
  },
  {
    value: 'No Light Perception',
    label: i18n.global.t('platform.eyes.va.no-light-perception', [], { locale }) as string
  },
  {
    value: 'No Eye',
    label: i18n.global.t('platform.eyes.va.no-eye', [], { locale }) as string
  },
  {
    value: 'Could Not Be Measured',
    label: i18n.global.t('platform.eyes.va.could-not-be-measured', [], { locale }) as string
  }
];
