import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-base font-medium inline-block",
  "data-dusk": "radio-label"
}
const _hoisted_2 = { class: "flex flex-wrap gap-3" }
const _hoisted_3 = {
  key: 1,
  class: "pt-1 mt-2 text-sm font-medium text-danger-800 leading-4 border-t border-danger-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseRadio = _resolveComponent("BaseRadio")!

  return (_openBlock(), _createElementBlock("div", null, [
    ((_ctx.icon && _ctx.icon.length) || (_ctx.label && _ctx.label.length))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{ 'text-transparent bg-gray-200 rounded-lg': _ctx.loading }, "flex items-center mb-3"])
        }, [
          (_ctx.icon && _ctx.icon.length)
            ? (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 0,
                name: _ctx.icon,
                height: _ctx.iconHeight,
                position: "left",
                class: _normalizeClass({ invisible: _ctx.loading })
              }, null, 8, ["name", "height", "class"]))
            : _createCommentVNode("", true),
          (_ctx.label && _ctx.label.length)
            ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          (_ctx.isOptional)
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass([_ctx.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize ml-3 mt-1"])
              }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_BaseRadio, {
          key: option.label ? option.label : option,
          "model-value": _ctx.modelValue,
          title: option.title,
          label: option.label ? option.label : option,
          value: option.label ? option.value : option,
          disabled: _ctx.disabled,
          loading: _ctx.loading,
          light: _ctx.light,
          name: _ctx.name,
          "label-position": _ctx.labelPosition,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdate($event)))
        }, null, 8, ["model-value", "title", "label", "value", "disabled", "loading", "light", "name", "label-position"]))
      }), 128))
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}