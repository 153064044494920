import { FullIndicator, IndicatorRequest } from '@/models';
import { GenericService } from './generic.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class PatientReviewIndicatorService extends GenericService<FullIndicator> {
  constructor(patientId: string, encounterId: string) {
    super();
    this.endpoint = `v1/patients/${patientId}/encounters/${encounterId}/indicators`;
  }

  async post(model: IndicatorRequest, params: AxiosRequestConfig = {}): Promise<AxiosResponse<FullIndicator>> {
    return await apiClient.post(this.endpoint, model, params);
  }
}
