import { Patient } from '@/models';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';

export const verifyMrn = (mrn: string): boolean => /^\S+$/.test(mrn);
export const validateName = (name: string): boolean => /^([^0-9]*)$/.test(name);

export const getNhsNumberFromPatient = (patient: Patient | null): string => {
  if (patient && patient.external_patient_references) {
    const patientNhsEpr = patient.external_patient_references.find(
      (epr) => epr.type && epr.type.key === UHB_NHS_NUMBER_KEY
    );
    if (patientNhsEpr && patientNhsEpr.value) {
      return formattedNHSNumber(patientNhsEpr.value);
    }
  }
  return '';
};

export const getDobFromISOString = (date: string): Date => {
  const d = new Date(date);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return new Date(year, month, day);
};

export const isMajorPatient = (patient: Patient): boolean => patient.merged_patient === null;
