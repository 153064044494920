import { GenericService } from '@/services/api/generic.service';
import { FormSubmission } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class PatientFormSubmissionService extends GenericService<FormSubmission> {
  endpoint = '';

  constructor(patientId: string) {
    super();
    this.endpoint = `v1/patients/${patientId}/form-submissions`;
  }

  async validateSection(
    formSubmissionId: string,
    sectionId: string,
    params: AxiosRequestConfig = {}
  ): Promise<
    AxiosResponse<{
      id: string;
      validation: {
        passes: boolean;
        errors: { [id: string]: string[] };
      };
    }>
    > {
    return await apiClient.get(`${this.endpoint}/${formSubmissionId}/sections/${sectionId}`, params);
  }
}
