
import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';
import { BaseIcon } from '@/lib/components/Icon';
import { ClearButton } from '@/lib/components/Button';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  components: {
    ClearButton,
    InputTextHelper,
    BaseIcon
  },

  props: {
    count: {
      type: Number,
      default: null
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    iconColor: {
      type: String,
      default: 'gray-500'
    },
    iconStroke: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    },
    successMessage: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value: string) => ['small', 'regular'].includes(value)
    },
    iconHeight: {
      type: String,
      default: '25px'
    },
    symbol: {
      type: String,
      default: null
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    decimalPlaces: {
      type: Number,
      default: 0
    },
    isOptional: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseTextInput extends Vue {
  id = v4();
  focused = false;
  readonly!: boolean;
  disabled!: boolean;
  modelValue!: string;
  decimalPlaces!: number;

  created() {
    if (this.modelValue?.length) {
      this.updateValue();
    }
  }

  focus() {
    if (!this.readonly && !this.disabled && !this.focused) {
      this.focused = true;
      this.$refs.inputRef.focus();
    }
  }

  blur() {
    if (!this.readonly && !this.disabled) {
      this.focused = false;
      this.updateValue();
    }
  }

  updateValue() {
    const isNumber = /^-?\d+(\.)?\d*$/.test(this.modelValue);
    if (this.decimalPlaces > 0 && isNumber) {
      const value = parseFloat(this.modelValue)
        .toFixed(this.decimalPlaces + 1)
        .slice(0, -1);
      if (this.modelValue !== value) {
        this.$emit('update:modelValue', value);
        this.$emit('change');
      }
    }
  }

  onClear() {
    this.$emit('update:modelValue', '');
    this.$emit('change');
    this.$emit('clear');
  }
}
