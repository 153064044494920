
import { Vue, Options } from 'vue-class-component';
import pickBy from 'lodash-es/pickBy';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';

import SmartFormRepeaterItem from './SmartFormRepeaterItem.vue';
import { FormItem } from '@/models';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  props: {
    id: {
      type: String,
      required: true
    },

    modelValue: {
      type: Array,
      default: () => []
    },

    error: {
      type: String,
      default: null
    },

    itemName: {
      type: String,
      default: ''
    },

    item: {
      type: Object,
      required: true
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseButton,
    BaseIcon,
    SmartFormRepeaterItem,
    InputTextHelper
  }
})
export default class SmartFormRepeater extends Vue {
  item!: FormItem;
  modelValue!: { [key: string]: any }[];
  id!: string;
  formSubmissionId!: string;
  smartForm = useSmartFormStore();

  rowTemplate =
    this.item.items?.reduce((row, item) => ({ ...row, [item.id]: null }), {}) ||
    {};

  rows: { [key: string]: any }[] = this.modelValue?.length
    ? [...this.modelValue]
    : [{ ...this.rowTemplate }];

  get populatedAnswers() {
    // Removing any empty values from each row
    return this.rows.map((row) => pickBy(row));
  }

  rowItemError(rowIndex: number, itemId: string) {
    return this.smartForm.getError(
      this.formSubmissionId,
      `${this.id}.${rowIndex}.${itemId}`
    );
  }

  addRow() {
    this.rows.push({ ...this.rowTemplate });
  }

  removeRow(index: number) {
    this.rows.splice(index, 1);
    this.emitInputEvent();
    this.emitChangeEvent();

    if (this.rows.length === 0) {
      this.addRow();
    }
  }

  emitInputEvent() {
    this.$emit('update:modelValue', this.populatedAnswers);
    this.$emit('input', this.populatedAnswers);
  }

  emitChangeEvent() {
    this.$emit('change', this.populatedAnswers);
  }

  get isRequired() {
    if (!this.item.rules) {
      return false;
    }

    return this.item.rules.split('|').some((rule) => rule.includes('required'));
  }
}
