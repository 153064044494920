import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3 leading-tight"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label && _ctx.label.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "inline-block text-base font-medium leading-tight"
          }, _toDisplayString(_ctx.label), 9, _hoisted_2),
          (_ctx.isOptional)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_DatePicker, {
      ref: "datePicker",
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
      masks: _ctx.datePickerMasks,
      mode: _ctx.mode,
      is24hr: _ctx.is24hr,
      "min-date": _ctx.minimumDate,
      "max-date": _ctx.maximumDate,
      locale: _ctx.localeToValidate,
      "input-debounce": 500
    }, {
      default: _withCtx(({ hidePopover }) => [
        _createVNode(_component_BaseTextInput, {
          "model-value": _ctx.localisedDate,
          placeholder: _ctx.localisedMask.toLowerCase(),
          icon: "calendar",
          "data-dusk": "date-of-birth-input",
          "icon-position": _ctx.iconPosition,
          "icon-color": _ctx.iconColor,
          "icon-size": "xl",
          class: "w-full",
          clear: _ctx.clear,
          error: _ctx.error,
          "success-message": _ctx.description,
          onClick: _ctx.showCalendar,
          onIconClick: _ctx.showCalendar,
          onBlur: hidePopover,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValueChange($event))),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('keyup', $event)), ["enter"])),
          onClear: _ctx.onValueClear
        }, null, 8, ["model-value", "placeholder", "icon-position", "icon-color", "clear", "error", "success-message", "onClick", "onIconClick", "onBlur", "onClear"])
      ]),
      _: 1
    }, 8, ["modelValue", "masks", "mode", "is24hr", "min-date", "max-date", "locale"])
  ]))
}