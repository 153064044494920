
import { Options, Vue } from 'vue-class-component';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import axios, { CancelTokenSource } from 'axios';
import { BaseAlert } from '@/lib/components/Banner';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';
import { CustomerBranding, Customer } from '@/models';

@Options({
  components: { PageLoading, BaseButton, BaseAlert },
  props: {
    type: {
      type: String,
      validator: (value: string) => ['privacy-policy', 'terms-service'].includes(value),
      default: 'privacy-policy'
    },
    countryCode: {
      type: String,
      default: ''
    },
    /**
     * Force all links to open in a new tab
     * Used when we don't want to take user away from current page - e.g new patient registration
     */
    forceLinksNewTab: {
      type: Boolean,
      default: false
    },
    branding: {
      type: Array,
      default: () => []
    },
    customers: {
      type: Array,
      default: () => []
    }
  }
})
export default class LegalPolicy extends Vue {
  legalPolicyHtmls: { brand: string, content: HTMLElement}[] = [];
  loading = true;
  error = false;
  type!: string;
  branding!: Customer['branding'][];
  customers!:Customer[];
  countryCode!: string;
  forceLinksNewTab!: boolean;
  isFallback = false;
  legalPolicyUrl = 'https://bigpicturemedical.com/legal';
  bpmBrand = CustomerBranding.BPM;
  request: CancelTokenSource | null = null;

  get isEnglishRegion(): boolean{
    return this.countryCode.includes('en') || ['gb', 'us', 'au', 'sg'].includes(this.countryCode);
  }

  get customerBrands(): Customer['branding'][] {
    return this.customers.length > 0
      ? this.customers
        .filter((customer) => customer.branding !== this.bpmBrand && customer.legal_regions.includes(this.countryCode))
        .map((customer) => customer.branding)
      : this.branding.filter((branding) => branding !== this.bpmBrand);
  }

  async mounted() {
    await this.fetchPolicyHtml();
    this.$watch('customerBrands', async () => {
      await this.fetchPolicyHtml();
    });
    this.$watch('countryCode', async () => {
      await this.fetchPolicyHtml();
    });
  }

  getHtmlUrl(countryCode: string, branding: string): string {
    const baseUrl = process.env.VUE_APP_LEGAL_BASE_URL ?? this.legalPolicyUrl;
    return `${baseUrl}/${branding}/${countryCode}/${this.type}`;
  }

  async fetchPolicyHtml() {
    this.request?.cancel();
    this.request = axios.CancelToken.source();
    this.loading = true;
    this.error = false;
    this.$emit('fetch-start');

    const legalPolicyHtmls = [];
    const brands = [this.bpmBrand, ...this.customerBrands];

    const allPromises = brands.map(async (brand) => {
      const brandedPolicy = await this.loadPolicy(this.countryCode, brand);
      if (brandedPolicy) {
        legalPolicyHtmls.push({
          brand,
          content: brandedPolicy.innerHTML
        });
      }
    });

    const results = await Promise.allSettled(allPromises);
    if (legalPolicyHtmls.length) {
      this.legalPolicyHtmls = legalPolicyHtmls.sort((a, b) => (a.brand > b.brand) ? 1 : -1);
      this.$emit('fetch-success');
    } else if (results.some((result) => result.status === 'rejected' && !axios.isCancel(result.reason))) {
      this.error = true;
    }

    this.loading = false;
  }

  async loadPolicy(countryCode: string, branding: string) {
    try {
      const responseData = (await axios.get(this.getHtmlUrl(countryCode, branding), {
        cancelToken: this.request?.token
      })).data;
      return this.removeHtmlClassAttrs(responseData);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // BPM will always fallback to gb
        if (branding === this.bpmBrand && countryCode !== DEFAULT_LEGAL_REGION) {
          this.isFallback = true;
          return this.loadPolicy(DEFAULT_LEGAL_REGION, this.bpmBrand);
        }
      }
    }
  }

  removeHtmlClassAttrs(htmlElement: string): HTMLElement {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlElement, 'text/html');
    const element = parsedHtml.querySelectorAll('div, h2, h3, p, a');
    for (let i = 0; i < element.length; i++) {
      if (element[i].className) {
        element[i].className = '';
      }
    }

    if (this.forceLinksNewTab) {
      const anchorTags = parsedHtml.getElementsByTagName('a');
      for (let i = 0; i < anchorTags.length; i++) {
        anchorTags[i].setAttribute('target', '_blank');
      }
    }

    return parsedHtml.body;
  }
}
