import { GenericService } from './generic.service';
import { Customer, User } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig } from 'axios';

export class UsersService extends GenericService<User> {
  endpoint = 'v1/users';

  async getUserCustomers(userId: User['id'], params: AxiosRequestConfig = {}): Promise<Customer[]> {
    return (await apiClient.get(`${this.endpoint}/${userId}/customers`, params)).data;
  }
}
