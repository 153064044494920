
import { Vue, Options } from 'vue-class-component';
import { IOption, ManagementDestinationOption, ManagementStepField } from '@/lib';
import { ManagementPlanStepSelected } from '@/models';
import { BaseSelect } from '@/lib/components/Select';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseButton } from '@/lib/components/Button';
import { BaseTextInput } from '@/lib/components/Input';
import { arrayUnique } from '@/helpers/array.helper';

@Options({
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: (value) => value.length >= 3
    },
    destinations: {
      type: Array,
      default: () => []
    },
    timeframes: {
      type: Array,
      default: () => []
    },
    selectedCombinations: {
      type: Array,
      default: () => []
    },
    stepIndex: {
      type: Number,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { BaseTextInput, BaseButton, BaseIcon, BaseSelect }
})
export default class ManagementPlanStep extends Vue {
  value!: Array<ManagementStepField>;
  updatedStep: ManagementPlanStepSelected = {
    destination_id: '',
    timeframe_id: '',
    notes: '',
    step_id: ''
  };

  timeframes!: Array<IOption>;
  destinations!: Array<ManagementDestinationOption>;
  selectedCombinations!: Array<ManagementPlanStepSelected>;
  timeframeFilterOptions: Array<IOption> = [];
  destinationFilterOptions: Array<IOption> = [];

  mounted() {
    this.init();
    this.$watch('value', () => this.init(), { deep: true });
    this.$watch('selectedCombinations', () => this.init(), { deep: true });
  }

  init() {
    this.updatedStep = {
      ...this.updatedStep,
      destination_id: this.destinationId,
      timeframe_id: this.timeframeId,
      notes: this.notes,
      step_id: this.value[0].step_id ? this.value[0].step_id : this.updatedStep.step_id
    };
    this.setTimeframesOptions();
    this.setDestinationsOptions();
  }

  updateOrCreateStep() {
    if (this.timeframeSelectStatus) {
      // set timeframe_id to none (option_id) when timeframeSelect is disabled.
      this.updatedStep = {
        ...this.updatedStep,
        timeframe_id: this.noneTimeframeOptionId
      };
    }
    if (this.isValid && !this.value[0].step_id) {
      this.$emit('addStep', this.updatedStep);
    } else if (this.value[0].step_id) {
      this.$emit('updateStep', this.updatedStep);
    }
  }

  get destinationId(): string {
    return this.value[0].value;
  }

  get timeframeId(): string {
    return this.value[1].value;
  }

  get notes(): string {
    return this.value[2].value;
  }

  get timeframeSelectStatus(): boolean {
    if (this.updatedStep.destination_id && this.destinations) {
      const findOption = this.destinations.find((option) => option.value === this.updatedStep.destination_id);
      return !!(findOption && !findOption.require_timeframe);
    }
    return false;
  }

  get noneTimeframeOptionId(): string {
    if (this.timeframes) {
      const noneOption = this.timeframes.find((option) => option.label === 'None');
      if (noneOption) {
        return noneOption.value;
      }
    }
    return '';
  }

  // steps with require_timeframe === false can only be selected once.
  setDestinationsOptions() {
    // When a user can choose or change timeframe, destination list should be updated too.
    const otherSelectedDestinationId = this.selectedCombinations
      .filter(
        (selected: ManagementPlanStepSelected) =>
          selected.destination_id !== this.updatedStep.destination_id &&
        selected.timeframe_id === this.updatedStep.timeframe_id
      )
      .map((selected: ManagementPlanStepSelected) => selected.destination_id);

    const noTimeframeDestinationIds = this.destinations
      .filter((destination: ManagementDestinationOption) => !destination.require_timeframe)
      .map((destination: ManagementDestinationOption) => destination.value);
    const selectedDestinationIds = arrayUnique(
      this.selectedCombinations.map((selected: ManagementPlanStepSelected) => selected.destination_id)
    );

    const intersectionIds = noTimeframeDestinationIds.filter((item) => selectedDestinationIds.includes(item));

    this.destinationFilterOptions = this.destinations.filter(
      (item) =>
        (!intersectionIds.includes(item.value) || this.updatedStep.destination_id === item.value) &&
        !otherSelectedDestinationId.includes(item.value)
    );
  }

  get destinationOptions(): Array<IOption> {
    // Force UI to refresh
    if (this.destinationId && this.timeframeId) {
      this.updatedStep = {
        ...this.updatedStep,
        destination_id: this.destinationId,
        timeframe_id: this.timeframeId
      };
    }
    this.setDestinationsOptions();
    return this.destinationFilterOptions.length ? this.destinationFilterOptions : this.destinations;
  }

  // if steps with require_timeframe === true
  // duplication combination can not be in the same action group
  setTimeframesOptions() {
    const otherSelectedTimeframeId = this.selectedCombinations
      .filter(
        (selected: ManagementPlanStepSelected) =>
          selected.destination_id === this.updatedStep.destination_id &&
        selected.timeframe_id !== this.updatedStep.timeframe_id
      )
      .map((selected: ManagementPlanStepSelected) => selected.timeframe_id);
    this.timeframeFilterOptions = this.timeframes.filter((item) => !otherSelectedTimeframeId.includes(item.value));
  }

  get timeframeOptions(): Array<IOption> {
    this.setTimeframesOptions();
    return this.timeframeFilterOptions.length ? this.timeframeFilterOptions : this.timeframes;
  }

  get isValid(): boolean {
    return this.updatedStep.destination_id !== '' && this.updatedStep.timeframe_id !== '';
  }

  removeStep(step_index: number) {
    this.$emit('removeStep', step_index);
  }
}
