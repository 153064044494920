
import { Vue, Options } from 'vue-class-component';
import { BaseIcon } from '@/lib/components/Icon';

@Options({
  components: {
    BaseIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    iconColour: {
      type: String,
      default: ''
    }
  }
})
export default class NavDropdownButton extends Vue {
}
