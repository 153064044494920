
import { Vue, Options } from 'vue-class-component';
import { IModalAction } from '@/lib';
import { OrganisationDeviceService } from '@/services/api';
import { BaseCard } from '@/lib/components/Card';
import { ActionModal } from '@/lib/components/Modals';
import { BaseButton } from '@/lib/components/Button';
import useClipboard from 'vue-clipboard3';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    deviceId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseCard,
    ActionModal,
    BaseButton
  }
})
export default class DeviceKey extends Vue {
  organisationId!: string;
  deviceId!: string;
  deviceKey = '**********************';
  saving = false;
  deviceKeyIsReady = false;
  deviceKeyModal = false;
  notificationStore = useNotificationStore();

  get deviceKeyModalActions(): IModalAction[] {
    return [
      {
        label: this.$t('platform.device.confirm-regenerate') as string,
        color: 'primary',
        onClick: () => this.generateDeviceKey()
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'gray',
        onClick: () => (this.deviceKeyModal = false)
      }
    ];
  }

  get deviceService(): OrganisationDeviceService {
    return new OrganisationDeviceService(this.organisationId);
  }

  created() {
    this.deviceKey = this.$route.query.deviceKey ? this.$route.query.deviceKey.toString() : '**********************';
    this.deviceKeyIsReady = !!this.$route.query.copyDeviceKey;
  }

  async onCopy() {
    try {
      const { toClipboard } = useClipboard();
      await toClipboard(this.deviceKey);
      await this.notificationStore.addSuccessNotification({
        title: this.$t('platform.device.copy-key-success')
      });
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.device.copy-key-error')
      });
    }
  }

  async generateDeviceKey() {
    try {
      if (this.deviceService) {
        this.saving = true;
        const response = await this.deviceService.generateDeviceKey(this.deviceId);
        this.deviceKey = response.key;
        this.deviceKeyIsReady = true;
        await this.notificationStore.addSuccessNotification({
          title: this.$t('platform.device.regenerate-key-success')
        });
      }
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.device.regenerate-key-error')
      });
    } finally {
      this.saving = false;
    }
  }
}
