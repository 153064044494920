import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "h-full focus:outline-none group cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["flex items-center h-full border-b-2", {
        'border-primary-500':_ctx.isActive,
        'border-transparent group-hover:border-gray-400 group-focus:border-gray-400':!_ctx.isActive
      }])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_BaseIcon, {
        name: _ctx.isOpen ? 'chevron-up' : 'chevron-down',
        class: _normalizeClass([_ctx.iconColour, "stroke-1.5"]),
        position: "right"
      }, null, 8, ["name", "class"])
    ], 2)
  ]))
}