import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute bottom-0 right-0 mb-12 mr-4 py-6 px-8 bg-danger-500 border border-danger-600 rounded-lg shadow-lg text-white"
}
const _hoisted_3 = { class: "whitespace-no-wrap" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "text-sm font-medium text-danger-300" }
const _hoisted_6 = ["slot-props"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrefillFormNotification = _resolveComponent("PrefillFormNotification")!
  const _component_SmartFormSection = _resolveComponent("SmartFormSection")!
  const _component_EmergencyModal = _resolveComponent("EmergencyModal")!
  const _component_SmartFormStatus = _resolveComponent("SmartFormStatus")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SmartFormComplete = _resolveComponent("SmartFormComplete")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.config.name,
    patient: _ctx.patient ? _ctx.patient : null,
    steps: _ctx.steps,
    "current-step": _ctx.step,
    status: _ctx.status,
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.smartFormDraft = true)),
    onSave: _ctx.saveAnswers
  }, {
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showValidationPopup && _ctx.errorCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('platform.form.error-message', { errorCount: _ctx.errorCount }, _ctx.errorCount)), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('platform.form.error-instructions')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.hideButtons)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            size: "large",
            color: "ghost",
            disabled: _ctx.loading,
            "data-dusk": "back-button",
            onClick: _ctx.back
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "workflow-footer-btn",
        "slot-props": { disabled: _ctx.loading }
      }, null, 8, _hoisted_6),
      (_ctx.step < _ctx.activeSections.length - 1 && !_ctx.hideButtons)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            size: "large",
            class: "ml-2",
            loading: _ctx.loading,
            "data-dusk": "next-button",
            onClick: _ctx.tryNext
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.next')) + " ", 1),
              _createVNode(_component_BaseIcon, { name: "arrow-right" })
            ]),
            _: 1
          }, 8, ["loading", "onClick"]))
        : (!_ctx.hideButtons)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 2,
              size: "large",
              color: "success",
              class: "ml-2",
              loading: _ctx.loading,
              "data-dusk": "complete-button",
              onClick: _ctx.complete
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')) + " ", 1),
                _createVNode(_component_BaseIcon, { name: "arrow-right" })
              ]),
              _: 1
            }, 8, ["loading", "onClick"]))
          : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.isPrefilled)
        ? (_openBlock(), _createBlock(_component_PrefillFormNotification, {
            key: 0,
            class: "max-w-6xl mx-auto mt-16",
            "created-at": _ctx.prefilledFormSubmission.created_at,
            username: 
        _ctx.prefilledFormSubmission.user.given_name +
          ' ' +
          _ctx.prefilledFormSubmission.user.family_name
      
          }, null, 8, ["created-at", "username"]))
        : _createCommentVNode("", true),
      (!_ctx.completed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["p-16", {
        'opacity-50': _ctx.loading,
        'pb-40': _ctx.showValidationPopup && _ctx.errorCount > 0
      }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeSections, (section, sectionIndex) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: section.id
              }, [
                (_ctx.step === sectionIndex)
                  ? (_openBlock(), _createBlock(_component_SmartFormSection, {
                      key: 0,
                      section: section,
                      "patient-id": _ctx.patientId,
                      "participant-id": _ctx.participantId,
                      "form-submission-id": _ctx.formSubmissionId,
                      next: _ctx.tryNext,
                      back: _ctx.back,
                      class: "max-w-6xl mx-auto"
                    }, null, 8, ["section", "patient-id", "participant-id", "form-submission-id", "next", "back"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: alert.id
              }, [
                (alert.active && !_ctx.closedModals.includes(alert.id))
                  ? (_openBlock(), _createBlock(_component_EmergencyModal, {
                      key: 0,
                      title: alert.title,
                      message: alert.message,
                      onExit: _ctx.saveAndExit,
                      onClose: ($event: any) => (_ctx.closeModal(alert.id))
                    }, null, 8, ["title", "message", "onExit", "onClose"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            (
          _ctx.config.status &&
            _ctx.config.status.display &&
            _ctx.step === _ctx.activeSections.length - 1
        )
              ? (_openBlock(), _createBlock(_component_SmartFormStatus, {
                  key: 0,
                  class: "max-w-6xl mx-auto mt-4",
                  "form-submission-id": _ctx.formSubmissionId,
                  "exclude-types": _ctx.config.status ? _ctx.config.status.excludeTypes : [],
                  "exclude-ids": _ctx.config.status ? _ctx.config.status.excludeIds : [],
                  sections: _ctx.activeSections
                }, null, 8, ["form-submission-id", "exclude-types", "exclude-ids", "sections"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.smartFormDraft)
        ? (_openBlock(), _createBlock(_component_SmartFormComplete, {
            key: 2,
            heading: _ctx.$t('custom.uhb.consult.exit'),
            body: _ctx.$t('custom.uhb.consult.exit-message'),
            icon: "warning",
            "icon-color": "alert-400",
            stroke: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "primary",
                class: "mt-6",
                "data-dusk": "exit-draft-btn",
                onClick: _ctx.draftAndExit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.exitDraftLabel), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "muted",
                class: "mt-3",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.smartFormDraft = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["heading", "body"]))
        : (_ctx.completed)
          ? (_openBlock(), _createBlock(_component_SmartFormComplete, _mergeProps({
              key: 3,
              "is-glyph": "",
              "icon-color": "success"
            }, _ctx.completedSection), {
              default: _withCtx(() => [
                _createVNode(_component_BaseButton, {
                  size: "large",
                  block: "",
                  color: "primary",
                  class: "mt-6",
                  onClick: _ctx.draftAndExit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.backToLabel), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_ButtonLink, {
                  size: "large",
                  block: "",
                  color: "muted",
                  class: "mt-3",
                  to: _ctx.exitToListLink
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.exitToListLabel), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }, 16))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "patient", "steps", "current-step", "status", "onSave"]))
}