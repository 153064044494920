import {
  Answer,
  FormAnswerValue,
  FormCondition,
  FormConfig,
  FormItem,
  FormSection,
  MedicationPrescriptionAnswerValue
} from '@/models';
import { MedicationPrescription } from '@/lib';
import { i18n } from '@/i18n/i18n';

export const compare = (value1: any, operator: string, value2: any): boolean => {
  switch (operator) {
  case '>':
    return value1 > value2;
  case '<':
    return value1 < value2;
  case '>=':
    return value1 >= value2;
  case '<=':
    return value1 <= value2;
  case '==':
    // eslint-disable-next-line eqeqeq
    return value1 == value2;
  case '!=':
    // eslint-disable-next-line eqeqeq
    return value1 != value2;
  case '===':
    return value1 === value2;
  case '!==':
    return value1 !== value2;
  case 'includes':
    return Array.isArray(value1) && value1.includes(value2);
  case 'not-includes':
    return Array.isArray(value1) && !value1.includes(value2);
  case 'in':
    return Array.isArray(value2) && value2.includes(value1);
  case 'not-in':
    return Array.isArray(value2) && !value2.includes(value1);
  case 'intersects':
    return Array.isArray(value1) && Array.isArray(value2) && value1.some((val) => value2.includes(val));
  case 'not-intersects':
    return Array.isArray(value1) && Array.isArray(value2) && !value1.some((val) => value2.includes(val));
  case 'nested-has':
    return (
      Array.isArray(value1) &&
        value2.split(':').length === 2 &&
        value1.some((obj: any) => obj[value2.split(':')[0]] === value2.split(':')[1])
    );
  default:
    return false;
  }
};

export const inheritConditions = (
  conditions1: FormCondition[][] | undefined,
  conditions2: FormCondition[][] | undefined
): FormCondition[][] => {
  const conditions: FormCondition[][] = [];
  if (conditions1 && conditions1.length && conditions2 && conditions2.length) {
    conditions1.forEach((c1: FormCondition[]) => {
      if (c1.length) {
        conditions2.forEach((c2: FormCondition[]) => {
          if (c2.length) {
            conditions.push(c1.concat(c2));
          } else {
            conditions.push(c1);
          }
        });
      }
    });
    return conditions;
  } else if (conditions2 && conditions2.length) {
    return conditions2;
  }
  return conditions1 || [];
};

export const getItemsRecursively = (item: FormItem): FormItem[] =>
  item.items ? [item, ...item.items.flatMap(getItemsRecursively)] : [item];

export const getSchemaItems = (config: FormConfig): Array<FormSection | FormItem> => [
  ...config.sections,
  ...config.sections.flatMap((section) => (section.items ? section.items.flatMap(getItemsRecursively) : []))
];

export const getItem = (schema: FormConfig, itemId: string) =>
  getSchemaItems(schema).find((item) => item.id === itemId);

export const getItemWithInheritedConditions = (
  item: FormItem | FormSection,
  items: Array<FormItem | FormSection>
): FormItem | FormSection => {
  const reducer = (acc: FormCondition[][], conditionGroup: FormCondition[]): FormCondition[][] => {
    const linkedConditions = conditionGroup.map((condition) => {
      const condItem = items.find((item) => item.id === condition.item);
      return condItem && condItem.conditions
        ? inheritConditions(condItem.conditions.reduce(reducer, [] as FormCondition[][]), [conditionGroup]).flat()
        : conditionGroup;
    });
    return [...acc, ...linkedConditions];
  };
  const itemWithNewConditions = {
    ...item,
    ...(item.conditions && item.conditions.length
      ? {
        conditions: item.conditions.reduce(reducer, [] as FormCondition[][])
      }
      : {})
  };
  if (itemWithNewConditions.items && itemWithNewConditions.items.length) {
    itemWithNewConditions.items = itemWithNewConditions.items.map(
      (i) =>
        getItemWithInheritedConditions(
          {
            ...i,
            conditions: inheritConditions(i.conditions, item.conditions)
          },
          items
        ) as FormItem
    );
  }
  return itemWithNewConditions;
};

export const getSchemaWithInheritedConditions = (config: FormConfig): FormConfig => ({
  ...config,
  sections: config.sections.map(
    (section) => getItemWithInheritedConditions(section, getSchemaItems(config)) as FormSection
  )
});

export const extractValueFromMedicationFormAnswer =
  (medication: MedicationPrescription | MedicationPrescriptionAnswerValue): any => {
    const values = {};
    for (const [key, value] of Object.entries(medication)) {
      // If the value is a primitive type, just return it
      if (value === null || typeof value !== 'object') {
        values[key] = value;
        continue;
      }

      // If the answer object has a value, return it
      if ('value' in value) {
        values[key] = value.value;
      } else {
        // Otherwise, process the sub-answer object
        values[key] = extractValueFromMedicationFormAnswer(value);
      }
    }
    return values;
  };

export const extractValueFromRepeaterFormAnswer = (answer: Record<string, FormAnswerValue>[]): Record<string, any>[] =>
  answer.map((row) => row ? (Object.keys(row).reduce((acc, id) => ({
    ...acc,
    ...(typeof row[id] === 'object' && Object.hasOwn(row[id], 'value') ? { [id]: row[id].value } : { [id]: row[id] })
  }), {})) : {}).filter((row) => Object.keys(row).length);

export const getAnswer = (store, formSubmissionId: string, item: FormItem, viewMode = false) => {
  const answer = store.getAnswer(formSubmissionId, item.id);

  if (typeof answer === 'undefined' || answer === null) {
    if (viewMode) {
      return `[${i18n.global.t('platform.common.not-answered')}]`;
    }

    // CheckboxGroup requires an array
    return item.type === 'checkbox' || item.type === 'search' ? [] : null;
  }

  if (item.type === 'repeater' && isRepeaterAnswer(answer)) {
    return extractValueFromRepeaterFormAnswer(answer);
  }

  if (item.component === 'custom/medication/MedicationQuestion' && isRepeaterAnswer(answer)) {
    return answer.map((row: MedicationPrescriptionAnswerValue) => extractValueFromMedicationFormAnswer(row));
  }

  return answer;
};

export const isRepeaterAnswer = (answer: Answer): boolean =>
  Array.isArray(answer) && (answer as any[]).every((value) => typeof value === 'object');
