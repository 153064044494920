
import { Vue, Options } from 'vue-class-component';
import axios, { CancelTokenSource } from 'axios';
import omitBy from 'lodash-es/omitBy';
import isNil from 'lodash-es/isNil';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseSelect } from '@/lib/components/Select';
import { BaseCheckbox } from '@/lib/components/Checkbox';
import { BaseDatePicker } from '@/lib/components/Date';

import { IValidationError } from '@/lib';
import { CopdMeasurementReviewParams, CopdOutcome, CopdProgramReviewConsult } from '@/models';
import { CopdProgramService } from '@/services/api';
import {
  changeHomeMonitoringPlanReasons,
  reviewOutcomes,
  consultTypes,
  selectableDischargeReasons
} from '@/helpers/home-monitoring.helper';
import dayjs from 'dayjs';

@Options({
  props: {
    programId: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    toBeReviewedList: {
      type: Array,
      default: () => []
    },
    nextConsult: {
      type: Object,
      default: null
    }
  },
  components: { BaseButton, BaseSelect, BaseIcon, BaseCheckbox, BaseDatePicker }
})
export default class ReviewMeasurementsModal extends Vue {
  status!: string;
  programId!: string;
  toBeReviewedList!: string[];
  request: CancelTokenSource | null = null;
  alreadyReviewed = false;
  reviewParams: CopdMeasurementReviewParams = {};
  patientScheduledExternally = false;
  nextConsult!: CopdProgramReviewConsult | null;

  errors: IValidationError = {
    errors: {},
    message: ''
  };

  copdProgramService = new CopdProgramService();

  mounted() {
    if (
      this.nextConsult &&
      (dayjs().isBefore(dayjs(this.nextConsult.consult_at)) || dayjs(this.nextConsult.consult_at).isToday())
    ) {
      this.reviewParams = {
        next_consult_at: this.nextConsult.consult_at,
        next_consult_type: this.nextConsult.consult_type
      };
    }

    this.$emit('record-event', 'started review of program measurement');
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get isDischargeReview() {
    return this.reviewParams.outcome === CopdOutcome.discharge;
  }

  get consultTypeList() {
    return consultTypes;
  }

  get outcomeList() {
    return reviewOutcomes;
  }

  get reasonOptions() {
    return this.reviewParams.outcome === CopdOutcome.discharge
      ? selectableDischargeReasons()
      : changeHomeMonitoringPlanReasons;
  }

  get ensureNextConsultAtIsOnOrAfterToday() {
    return (
      this.reviewParams.next_consult_at &&
      (this.reviewParams.next_consult_at >= this.today)
    );
  }

  get submitDisabled(): boolean {
    if (this.isDischargeReview) {
      return false;
    }
    if (this.patientScheduledExternally) {
      return !this.ensureNextConsultAtIsOnOrAfterToday;
    }
    return true;
  }

  updateReviewParams(newParams: CopdMeasurementReviewParams) {
    this.reviewParams = omitBy({ ...this.reviewParams, ...newParams }, isNil);

    // clear errors on updated params
    Object.keys(newParams).forEach((updatedField) => {
      this.errors?.errors && delete this.errors.errors[updatedField];
    });

    // clear the message in case there are no errors
    if (!Object.keys(this.errors.errors).length) {
      this.errors.message = '';
    }
  }

  closeReviewModal() {
    this.reviewParams = {};
    this.$emit('close');
  }

  async reviewMeasurements() {
    try {
      this.reviewParams = {
        ...this.reviewParams,
        measurements: this.toBeReviewedList
      };

      this.request = axios.CancelToken.source();
      await this.copdProgramService.reviewMeasurements(this.programId as string, this.reviewParams, {
        cancelToken: this.request.token
      });

      this.$emit('record-event', 'completed review of program measurement');
      this.$emit('close', true);
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.errors = e.response.data;
      }
      if (e.response.status === 400) {
        this.errors = { message: e.response.data.message, errors: {} };
        this.alreadyReviewed = true;
      } else {
        this.errors = e.response.data;
      }
    }
  }
}
