import { IOption } from '@/lib';
import { i18n } from '@/i18n/i18n';
import {
  FormAnswer,
  FormAnswerValue,
  FormItem,
  ImageSeries,
  Study,
  MedicationPrescriptionAnswerValue
} from '@/models';
import { defaultRoutes, doseUnitOptions, frequencyOptions } from '@/custom/medication/constants';
import {
  Encounter,
  EncounterTypeName,
  FormSummaryAnswerLayout,
  Laterality,
  Order,
  OrderItem,
  PrescriptionParams,
  VaUnit
} from '@/custom/menicon/models';
import dayjs from 'dayjs';
import { getDifferenceMapsAssessment } from '@/custom/menicon/helpers/lens-order.helper';
import { K_VALUES_PRECISION, lensParametersUnits } from '@/custom/menicon/constants';
import { isSet } from '@/custom/menicon/helpers/validators.helper';
import { getVaOptions } from '@/custom/menicon/helpers/encounter.helper';
import { isRepeaterAnswer } from '@/helpers/smart-form.helper';
import { localisedMask } from '@/helpers/date.helper';

export const convertMedicationAnswerToTableAnswer = (
  medication: FormItem,
  value: MedicationPrescriptionAnswerValue[],
  locale = i18n.global.locale.value
) => {
  const routes = defaultRoutes(locale);
  const frequencies = frequencyOptions(locale);
  const doseUnits = doseUnitOptions(locale);
  const rows: string[][] = value
    .filter((medication) => medication?.medication?.name?.value)
    .map(({ medication, dose, prn, frequency, route, doseUnit }: MedicationPrescriptionAnswerValue) => [
      medication?.name?.value,
      routes.find(({ value }) => value === route?.value)?.label ?? i18n.global.t('platform.common.not-answered', [], { locale }),
      dose?.value ? `${dose.value}${doseUnits.find(({ value }) => value === doseUnit?.value)?.label ?? ''}` : i18n.global.t('platform.common.not-answered', [], { locale }),
      frequencies.find(({ value }) => value === frequency?.value)?.label ?? i18n.global.t('platform.common.not-answered', [], { locale }),
      formatBooleanAnswer(prn?.value ?? false, locale)
    ]);
  const columns: string[][] = [
    i18n.global.t('platform.medication.medication', [], { locale }),
    i18n.global.t('platform.medication.route', [], { locale }),
    i18n.global.t('platform.medication.dose', [], { locale }),
    i18n.global.t('platform.medication.frequency', [], { locale }),
    i18n.global.t('platform.medication.prn', [], { locale })
  ];
  return { columns, rows, border: true };
};

export const convertRepeaterAnswerToTableAnswer = (
  repeater: FormItem,
  value: FormAnswer[],
  locale = i18n.global.locale.value
) => {
  const columns = repeater.items?.map(({ label }) => label) || [];
  const rows = [];
  for (const [key, line] of Object.entries(value)) {
    const colIds = repeater.items?.map(({ id }) => id) || [];
    rows[key] = colIds.map((colId) => {
      const item = repeater.items?.find(({ id }) => id === colId);
      return formatFormSchemaAnswer(line[colId], item, locale);
    });
  }

  return { columns, rows, border: true };
};

export const formatFormSchemaAnswer =
  (answer: FormAnswerValue, item: FormItem, locale = i18n.global.locale.value): any => {
    if (typeof answer === 'undefined') {
      return i18n.global.t('platform.common.not-answered', [], { locale });
    }

    const localAnswer = answer.value;

    if (item.type === 'repeater' && isRepeaterAnswer(localAnswer)) {
      return convertRepeaterAnswerToTableAnswer(item, localAnswer as FormAnswer[], locale);
    }

    if (item.component === 'custom/medication/MedicationQuestion' && isRepeaterAnswer(localAnswer)) {
      return convertMedicationAnswerToTableAnswer(item, localAnswer as MedicationPrescriptionAnswerValue[], locale);
    }

    if (item.type === 'slider' && item.props && typeof localAnswer === 'number') {
      return item.props.options[localAnswer];
    }

    if (Array.isArray(localAnswer)) {
      if (item.type === 'checkbox' && item.props) {
        return localAnswer
          .map((localAnswer) => {
            const options = item.props?.options || [];
            const option = options && options.find((opt: IOption) => opt.value === localAnswer);
            return option ? option.label : null;
          })
          .filter(Boolean)
          .join(', ');
      } else if (item.type === 'file') {
        return i18n.global.t('platform.common.file-upload.count-message', [localAnswer.length], { locale });
      }
      return localAnswer.join(', ');
    }

    if (typeof localAnswer === 'boolean') {
      return formatBooleanAnswer(localAnswer, locale);
    }

    if (item.props?.options && Array.isArray(item.props.options) &&
      !item.props.options.find(({ label, value }) => !label || !value)) {
      const option = item.props.options.find((opt: IOption) => opt.value === localAnswer);
      if (option) {
        return option.label;
      }
    }

    if (item.type === 'date' && dayjs(localAnswer).isValid()) {
      return dayjs(localAnswer).format(localisedMask(locale, item.props?.mode || 'date'));
    }

    if (item.props?.symbol && typeof localAnswer === 'string') {
      return `${localAnswer} ${item.props.symbol}`;
    }

    return localAnswer;
  };

export interface EncounterQuestionSummaryData {
  label: string;
  value?: any;
  laterality?: Laterality | 'both' | 'spacer';
  type?: FormSummaryAnswerLayout | 'heading';
  display?: 'row' | 'column';
  class?: {
    line?: string;
    question?: string;
  };
}

export interface EncounterSummaryData {
  vaAndRefraction: EncounterQuestionSummaryData[];
  distanceVa: EncounterQuestionSummaryData[];
  measurements: EncounterQuestionSummaryData[];
  lensOrdering: EncounterQuestionSummaryData[];
  slitLamp: EncounterQuestionSummaryData[];
  preFittingSlitLamp: EncounterQuestionSummaryData[];
  differenceMaps: EncounterQuestionSummaryData[];
}

export const getEncounterSummaryData = (
  encounter: Encounter,
  scans: Record<Laterality, ImageSeries | null> = { right: null, left: null },
  order: Order | null = null,
  locale: string = i18n.global.locale.value
): EncounterSummaryData => ({
  vaAndRefraction: [
    ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT
      ? [
        {
          label: i18n.global.t('custom.menicon.select-required-eyes', [], { locale }) as string,
          value: encounter.laterality
            ? i18n.global.t(`platform.eyes.${encounter.laterality}-eye`, [], { locale })
            : i18n.global.t('platform.eyes.both-eyes', [], { locale })
        }
      ]
      : []),
    getVARefractionData(encounter, Laterality.right, locale),
    getVARefractionData(encounter, Laterality.left, locale),
    getVARefractionData(encounter, 'both', locale),
    ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT ||
    encounter.type === EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT ||
    encounter.type === EncounterTypeName.BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT
      ? [
        {
          label: i18n.global.t('custom.menicon.encounter.va-refraction.cyclopegic', [], { locale }) as string,
          value: formatBooleanAnswer(encounter.is_cyclopegic_refraction, locale)
        }
      ]
      : [])
  ],
  distanceVa: [
    {
      label: i18n.global.t('platform.eyes.distance-va', [], { locale }) as string,
      type: 'grid',
      display: 'row',
      value: [
        {
          icon: 'right-eye',
          value: getVaLabel(encounter.va_unit, encounter.r_distance_visual_acuity, locale) || i18n.global.t('platform.common.not-answered', [], { locale })
        },
        {
          icon: 'left-eye',
          value: getVaLabel(encounter.va_unit, encounter.l_distance_visual_acuity, locale) || i18n.global.t('platform.common.not-answered', [], { locale })
        },
        {
          icon: 'both-eyes',
          value:
            getVaLabel(encounter.va_unit, encounter.combined_distance_visual_acuity, locale) || i18n.global.t('platform.common.not-answered', [], { locale })
        }
      ]
    },
    // @ts-ignore
    ...(encounter.type === EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT
      ? [
        {
          label: i18n.global.t(
            'custom.menicon.encounter.bloom-day-6-or-12-assessment.va-satisfactory',
            [],
            { locale }
          ) as string,
          type: 'grid',
          display: 'row',
          value: [
            {
              icon: 'right-eye',
              value: formatBooleanAnswer(encounter.r_is_va_satisfactory, locale)
            },
            {
              icon: 'left-eye',
              value: formatBooleanAnswer(encounter.l_is_va_satisfactory, locale)
            }
          ]
        }
      ]
      : [
        {
          label: i18n.global.t('custom.menicon.encounter.va.satisfactory', [], { locale }) as string,
          type: 'string',
          display: 'row',
          value: formatBooleanAnswer(encounter.is_va_satisfactory, locale)
        }
      ])
  ],
  measurements: [
    {
      label: '',
      type: 'table',
      value: {
        columns: [
          {
            icon: 'right-eye',
            value: i18n.global.t('platform.eyes.right-eye', [], { locale }) as string,
            class: 'flex items-center space-x-3 text-base font-semibold text-black'
          },
          i18n.global.t('platform.eyes.flat-k', [], { locale }),
          i18n.global.t('platform.eyes.steep-k', [], { locale }),
          i18n.global.t('platform.eyes.flat-k-axis', [], { locale }),
          ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT ? [i18n.global.t('platform.eyes.hvid', [], { locale })] : [])
        ],
        rows: [
          [
            scans.right
              ? {
                class: 'text-gray-500 text-sm font-medium',
                value: dayjs(scans.right.seriesDate).format('DD MMM YYYY, HH:mm')
              }
              : '',
            isSet(encounter.r_flat_k)
              ? `${(+(encounter.r_flat_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.r_steep_k)
              ? `${(+(encounter.r_steep_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.r_flat_k_axis)
              ? `${(+(encounter.r_flat_k_axis as string)).toFixed(K_VALUES_PRECISION)}˚`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT
              ? [
                isSet(encounter.r_hvid)
                  ? `${Math.round(+(encounter.r_hvid as string) * 1000) / 1000} mm`
                  : i18n.global.t('platform.common.not-answered', [], { locale })
              ]
              : [])
          ]
        ]
      }
    },
    {
      label: '',
      type: 'table',
      value: {
        columns: [
          {
            icon: 'left-eye',
            value: i18n.global.t('platform.eyes.left-eye', [], { locale }) as string,
            class: 'flex items-center space-x-3 text-base font-semibold text-black'
          },
          i18n.global.t('platform.eyes.flat-k', [], { locale }),
          i18n.global.t('platform.eyes.steep-k', [], { locale }),
          i18n.global.t('platform.eyes.flat-k-axis', [], { locale }),
          ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT ? [i18n.global.t('platform.eyes.hvid', [], { locale })] : [])
        ],
        rows: [
          [
            scans.left
              ? {
                class: 'text-gray-500 text-sm font-medium',
                value: dayjs(scans.left.seriesDate).format('DD MMM YYYY, HH:mm')
              }
              : '',
            isSet(encounter.l_flat_k)
              ? `${(+(encounter.l_flat_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.l_steep_k)
              ? `${(+(encounter.l_steep_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.l_flat_k_axis)
              ? `${(+(encounter.l_flat_k_axis as string)).toFixed(K_VALUES_PRECISION)}˚`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT
              ? [
                isSet(encounter.l_hvid)
                  ? `${Math.round(+(encounter.l_hvid as string) * 1000) / 1000} mm`
                  : i18n.global.t('platform.common.not-answered', [], { locale })
              ]
              : [])
          ]
        ]
      }
    }
  ],
  lensOrdering: order
    ? [
      ...getOrderItemAndPrescription(order, Laterality.right, locale),
      ...getOrderItemAndPrescription(order, Laterality.left, locale),
      {
        label: i18n.global.t('custom.menicon.lens-ordering.order-comments-label', [], { locale }) as string,
        value: order.comments || i18n.global.t('platform.common.not-answered', [], { locale })
      }
    ]
    : [],
  differenceMaps: [
    {
      label: i18n.global.t('custom.menicon.difference-maps.axial-dm', [], { locale }) as string,
      type: 'heading'
    },
    {
      label: i18n.global.t('custom.menicon.difference-maps.questions.treatment-zone', [], { locale }) as string,
      type: 'grid',
      display: 'row',
      value: [
        ...(typeof encounter.r_is_expected_treatment_zone === 'boolean'
          ? [
            {
              icon: 'right-eye',
              value: formatBooleanAnswer(encounter.r_is_expected_treatment_zone, locale)
            }
          ]
          : [{}]),
        ...(typeof encounter.l_is_expected_treatment_zone === 'boolean'
          ? [
            {
              icon: 'left-eye',
              value: formatBooleanAnswer(encounter.l_is_expected_treatment_zone, locale)
            }
          ]
          : [])
      ]
    },
    {
      label: i18n.global.t('custom.menicon.difference-maps.tangential-dm', [], { locale }) as string,
      type: 'heading'
    },
    {
      label: i18n.global.t('custom.menicon.difference-maps.questions.centration', [], { locale }) as string,
      type: 'grid',
      display: 'row',
      value: [
        ...(typeof encounter.r_is_expected_centration === 'boolean'
          ? [
            {
              icon: 'right-eye',
              value: formatBooleanAnswer(encounter.r_is_expected_centration, locale)
            }
          ]
          : [{}]),
        ...(typeof encounter.l_is_expected_centration === 'boolean'
          ? [
            {
              icon: 'left-eye',
              value: formatBooleanAnswer(encounter.l_is_expected_centration, locale)
            }
          ]
          : [])
      ]
    },
    ...getPatternQuestions(encounter, locale),
    {
      label: i18n.global.t('custom.menicon.difference-maps.summary', [], { locale }) as string,
      type: 'grid',
      display: 'row',
      class: {
        line: 'py-3 bg-gray-50 rounded-md mt-4',
        question: 'text-gray-900 pl-3 font-semibold'
      },
      value: [
        ...(typeof encounter.r_is_expected_treatment_zone === 'boolean'
          ? [
            {
              icon: 'right-eye',
              value: i18n.global.t(
                `custom.menicon.difference-maps.assessment.${getDifferenceMapsAssessment(
                  encounter.r_is_expected_treatment_zone,
                  encounter.r_is_expected_centration,
                  encounter.r_pattern
                )}`,
                [],
                { locale }
              )
            }
          ]
          : [{}]),
        ...(typeof encounter.l_is_expected_treatment_zone === 'boolean'
          ? [
            {
              icon: 'left-eye',
              value: i18n.global.t(
                `custom.menicon.difference-maps.assessment.${getDifferenceMapsAssessment(
                  encounter.l_is_expected_treatment_zone,
                  encounter.l_is_expected_centration,
                  encounter.l_pattern
                )}`,
                [],
                { locale }
              )
            }
          ]
          : [])
      ]
    }
  ],
  slitLamp: getSlitLampData(encounter.slit_lamp_conditions,
    encounter.is_slit_lamp_condition_none,
    encounter.slit_lamp_details,
    locale),
  preFittingSlitLamp: getSlitLampData(encounter.pre_fitting_slit_lamp_conditions,
    encounter.pre_fitting_is_slit_lamp_condition_none,
    encounter.pre_fitting_slit_lamp_details,
    locale)
});

export const getSlitLampData = (conditions: Encounter['slit_lamp_conditions'], isNone?: boolean, details?: string, locale = i18n.global.locale.value): EncounterQuestionSummaryData[] => {
  let formattedConditions = i18n.global.t('platform.common.not-answered', [], { locale });
  let grading: EncounterQuestionSummaryData[] = [];
  if (isNone) {
    formattedConditions = i18n.global.t('custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.none', [], { locale });
  } else if (conditions) {
    formattedConditions = Object
      .keys(conditions)
      .map((condition) =>
        i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.${condition}`, [], { locale })
      ).join(', ');
    grading = Object.keys(conditions).map((condition) => ({
      label: i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.grade.${condition}`, [], { locale }) as string,
      type: 'grid',
      display: 'row',
      value: [
        {
          icon: 'right-eye',
          value: conditions?.[condition]?.r_grading ?
            i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.grade.${conditions[condition]?.r_grading}`, [], { locale }) :
            i18n.global.t('platform.common.not-answered', [], { locale })
        },
        {
          icon: 'left-eye',
          value: conditions?.[condition]?.l_grading ?
            i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.grade.${conditions[condition]?.l_grading}`, [], { locale }) :
            i18n.global.t('platform.common.not-answered', [], { locale })
        }
      ]
    }));
  }
  return [
    {
      display: 'row',
      label: i18n.global.t('custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.question', [], { locale }) as string,
      value: formattedConditions
    },
    ...grading,
    {
      display: 'row',
      label: i18n.global.t('custom.menicon.encounter.slit-lamp-without-lenses.further-details.label', [], { locale }) as string,
      value: details || i18n.global.t('platform.common.not-answered', [], { locale })
    }
  ];
};

export const vaRefractionProps = (encounter: Encounter, locale = i18n.global.locale.value) => {
  const encounterType = encounter.type;
  const defaultValue = {
    showAdd: isSet(encounter.r_addition) || isSet(encounter.l_addition),
    showAxialLength: false,
    vaLabel: i18n.global.t('platform.eyes.bcva', [], { locale })
  };
  switch (encounterType) {
  case EncounterTypeName.INITIAL_MEASUREMENT:
  case EncounterTypeName.BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT:
  case EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT:
    return {
      ...defaultValue,
      showAxialLength: true
    };
  case EncounterTypeName.BLOOM_NIGHT_COLLECTION:
    return {
      showAdd: false,
      showAxialLength: false,
      vaLabel: i18n.global.t('platform.eyes.va-with-lenses', [], { locale })
    };
  case EncounterTypeName.BLOOM_NIGHT_3_WEEK_LENS_EFFICACY_ASSESSMENT:
  case EncounterTypeName.BLOOM_NIGHT_3_OR_9_MONTHS_ASSESSMENT:
    return {
      ...defaultValue,
      showAdd: false
    };
  default:
    return defaultValue;
  }
};

export const getVaLabel = (vaUnit: VaUnit, value?: string, locale = i18n.global.locale.value): string | undefined =>
  getVaOptions(vaUnit, locale).find((option) => option.value === value)?.label;

export const getVARefractionData = (
  encounter: Encounter,
  laterality: Laterality | 'both',
  locale = i18n.global.locale.value
): EncounterQuestionSummaryData => {
  const data = vaRefractionProps(encounter, locale);
  return {
    label: '',
    type: 'table',
    laterality,
    value: {
      columns: [
        ...(laterality === 'both'
          ? [data.vaLabel, ...Array(3).fill(''), ...(data.showAdd ? [''] : []), ...(data.showAxialLength ? [''] : [])]
          : [
            i18n.global.t('platform.eyes.sphere', [], { locale }),
            i18n.global.t('platform.eyes.cylinder', [], { locale }),
            i18n.global.t('platform.eyes.axis', [], { locale }),
            data.vaLabel,
            ...(data.showAdd ? [i18n.global.t('platform.eyes.add', [], { locale })] : []),
            ...(data.showAxialLength ? [i18n.global.t('platform.eyes.axial-length', [], { locale })] : [])
          ])
      ],
      rows: [
        [
          ...(laterality === 'both'
            ? [
              getVaLabel(encounter.va_unit, encounter.combined_visual_acuity, locale),
              ...Array(3).fill(''),
              ...(data.showAdd ? [''] : []),
              ...(data.showAxialLength ? [''] : [])
            ]
            : [
              laterality === Laterality.left ? (isSet(encounter.l_sphere) ? `${encounter.l_sphere} D` : null) : (isSet(encounter.r_sphere) ? `${encounter.r_sphere} D` : null),
              laterality === Laterality.left ? (isSet(encounter.l_cylinder) ? `${encounter.l_cylinder} D` : null) : (isSet(encounter.r_cylinder) ? `${encounter.r_cylinder} D` : null),
              laterality === Laterality.left ? (isSet(encounter.l_axis) ? `${encounter.l_axis}º` : null) : (isSet(encounter.r_axis) ? `${encounter.r_axis}º` : null),
              laterality === Laterality.left ?
                getVaLabel(encounter.va_unit, encounter.l_visual_acuity, locale) :
                getVaLabel(encounter.va_unit, encounter.r_visual_acuity, locale),
              ...(data.showAdd
                ? [laterality === Laterality.left ? (isSet(encounter.l_addition) ? `${encounter.l_addition} D` : null) : (isSet(encounter.r_addition) ? `${encounter.r_addition} D` : null)]
                : []),
              ...(data.showAxialLength
                ? [laterality === Laterality.left ? (isSet(encounter.l_axial_length) ? `${encounter.l_axial_length} mm` : null) : (isSet(encounter.r_axial_length) ? `${encounter.r_axial_length} mm` : null)]
                : [])
            ])
        ]
      ]
    }
  };
};

export const getPatternQuestions = (
  encounter: Encounter,
  locale = i18n.global.locale.value
): EncounterQuestionSummaryData[] => {
  const displayRPattern =
    encounter.r_is_expected_treatment_zone === false && encounter.r_is_expected_centration === true;
  const displayLPattern =
    encounter.l_is_expected_treatment_zone === false && encounter.l_is_expected_centration === true;
  if (displayRPattern || displayLPattern) {
    const result: EncounterQuestionSummaryData[] = [
      {
        label: i18n.global.t('custom.menicon.difference-maps.pattern', [], { locale }) as string,
        type: 'heading'
      }
    ];
    if (displayRPattern) {
      result.push({
        label: i18n.global.t('custom.menicon.difference-maps.questions.right-eye-pattern', [], { locale }) as string,
        type: 'grid',
        display: 'row',
        value: [
          {
            icon: 'right-eye',
            value:
              encounter.r_pattern === 'a'
                ? i18n.global.t('custom.menicon.difference-maps.pattern-a', [], { locale })
                : i18n.global.t('custom.menicon.difference-maps.pattern-b', [], { locale })
          }
        ]
      });
    }
    if (displayLPattern) {
      result.push({
        label: i18n.global.t('custom.menicon.difference-maps.questions.left-eye-pattern', [], { locale }) as string,
        type: 'grid',
        display: 'row',
        value: [
          {},
          {
            icon: 'left-eye',
            value:
              encounter.l_pattern === 'a'
                ? i18n.global.t('custom.menicon.difference-maps.pattern-a', [], { locale })
                : i18n.global.t('custom.menicon.difference-maps.pattern-b', [], { locale })
          }
        ]
      });
    }
    return result;
  }
  return [];
};

export const getOrderItemAndPrescription = (
  order: Order,
  laterality: Laterality,
  locale = i18n.global.locale.value
): EncounterQuestionSummaryData[] => {
  const orderItem = order.orderItems.find((orderItem: OrderItem) => orderItem.prescription?.laterality === laterality);

  if (!orderItem || !orderItem.prescription) {
    return [];
  }

  const prescriptionValue = getLensOrderingPrescriptionValue(orderItem.prescription?.parameters, locale);

  const orderItemColumns = [
    i18n.global.t('custom.menicon.lens', [], { locale }),
    i18n.global.t('custom.menicon.pack', [], { locale }),
    i18n.global.t('custom.menicon.qty', [], { locale })
  ];

  orderItemColumns.push(
    ...Array(prescriptionValue.length - orderItemColumns.length)
      .join('.')
      .split('.')
  );

  return [
    {
      label: '',
      type: 'table',
      laterality,
      value: {
        columns: orderItemColumns,
        rows: [
          [
            orderItem.prescription?.parameters.lensName,
            orderItem.unit_of_measurement,
            orderItem.quantity,
            ...Array(prescriptionValue.length - 3).fill('')
          ]
        ]
      }
    },
    {
      label: '',
      type: 'table',
      laterality: 'spacer',
      value: {
        columns: [
          ...Object.keys(orderItem.prescription?.parameters.lensParameters).map((param) =>
            i18n.global.t(`custom.menicon.easyfit.Lens/${param}`, [], { locale })
          )
        ],
        rows: [prescriptionValue]
      }
    }
  ];
};

export const getLensOrderingPrescriptionValue = (parameters: PrescriptionParams, locale = i18n.global.locale.value) => {
  const result: string[] = [];
  for (const parameter in parameters.lensParameters) {
    if (parameter === 'Material') {
      result.push(parameters.materialName);
    } else if (parameter === 'TrialLens') {
      result.push(
        i18n.global.t(`custom.menicon.easyfit.${Boolean(+parameters.lensParameters[parameter])}`, [], { locale })
      );
    } else {
      result.push(`${parameters.lensParameters[parameter]}${lensParametersUnits[parameter] ?? ''}`);
    }
  }

  return result;
};

export const formatBooleanAnswer = (answer: boolean | undefined, locale = i18n.global.locale.value): string => {
  if (typeof answer === 'undefined') {
    return i18n.global.t('platform.common.not-answered', [], { locale }) as string;
  }

  return answer
    ? (i18n.global.t('platform.common.yes', [], { locale }) as string)
    : (i18n.global.t('platform.common.no', [], { locale }) as string);
};

export const getEncounterScans = (studies: Study[], encounter: Encounter): Record<Laterality, ImageSeries | null> =>
  studies.reduce(
    (acc, study) => {
      if (encounter.r_mls_id) {
        const rSeries = study.series.find(
          (series) => series.laterality === 'R' && series.topographerParams.mlsId === encounter.r_mls_id
        );
        if (rSeries) {
          acc.right = rSeries;
        }
      }
      if (encounter.l_mls_id) {
        const lSeries = study.series.find(
          (series) => series.laterality === 'L' && series.topographerParams.mlsId === encounter.l_mls_id
        );
        if (lSeries) {
          acc.left = lSeries;
        }
      }
      return acc;
    },
    { right: null, left: null } as Record<Laterality, ImageSeries | null>
  );
