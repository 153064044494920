
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import { getSeriesDescription } from '@/helpers/series.helper';
import { ImageSeries } from '@/models';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { LoadingIcon } from '@/lib/components/Loading';
import { ZephyrImageService } from '@/services/api';
import { i18n } from '@/i18n/i18n';

@Options({
  components: { BaseIcon, LoadingIcon, BaseButton },
  props: {
    imageSeries: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    signs: {
      type: Array,
      default: () => []
    },
    scanHoverMessage: {
      type: String,
      default() {
        return i18n.global.t('platform.scans.open-in-viewer');
      }
    },
    hoverClass: {
      type: String,
      default: 'shadow-lg'
    }
  }
})
export default class StudyGalleryItem extends Vue {
  hover = false;
  imageSeries!: ImageSeries;
  zephyrImageService = new ZephyrImageService();

  get description() {
    return getSeriesDescription(this.imageSeries);
  }

  get thumbnailUri() {
    const image = this.imageSeries.images.find((image) => image.thumbnailUri);
    if (image) {
      return image.thumbnailUri;
    } else if (this.imageSeries.images.length) {
      const route = this.zephyrImageService.getEndpointUrl();
      return `${route}/${this.imageSeries.images[0].id}?lossy=1`;
    }
    return null;
  }

  formatDate(date: string) {
    return dayjs(date).format('MMM D, YYYY h:mma');
  }
}
