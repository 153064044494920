
import { Vue, Options } from 'vue-class-component';
import { PageLoading } from '@/lib/components/Loading';
import { PdfViewer } from '@/lib/components/Viewer';
import { BaseModal } from '@/lib/components/Modals';
import { FileService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    documentId: {
      type: String,
      required: true
    }
  },
  components: {
    PageLoading,
    PdfViewer,
    BaseModal
  }
})
export default class PatientSupportingDocumentModal extends Vue {
  documentId!: string;
  fileService = new FileService();
  pdf = '';
  notificationStore = useNotificationStore();

  mounted() {
    this.getPdf(this.documentId);
  }

  async getPdf(id: string) {
    try {
      const response = await this.fileService.fetchBase64Pdf(id);

      this.pdf = `data:application/pdf;base64,${response}`;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.error-retrieving-document'),
        label: e.response.data
      });
      this.$emit('close', e);
    }
  }

  closeModal() {
    this.$emit('close');
  }
}
