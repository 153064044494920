
import { Options, Vue } from 'vue-class-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FormConfig, FormItem, FormSection, ManagementPlan, ManagementPlanStep, Patient } from '@/models';
import { EpisodeOfCareEncounter, EpisodeOfCareResponseData } from '@/models/episode-of-care/episode-of-care.model';
import {
  CodeableConceptService,
  DocumentTemplateService,
  EncounterService,
  ManagementPlanService,
  PatientDocumentService,
  PatientFormSubmissionService
} from '@/services/api';
import { Encounter } from '@/models/episode-of-care/encounter.model';
import { CodeableConcept, IOption } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import CopyableTextSummary from '@/lib/components/CopyableTextSummary.vue';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { codeableConceptCategories } from '@/constants';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(utc);

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    episodeOfCare: {
      type: Object,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { BaseIcon, CopyableTextSummary }
})
export default class ReviewSummary extends Vue {
  patient!: Patient;
  organisationId!: string;
  episodeOfCare!: EpisodeOfCareResponseData;
  ambulatoryEncounter?: EpisodeOfCareEncounter;
  virtualEncounter: Encounter | null = null;

  formSubmissionId!: string;
  managementPlan: ManagementPlan | null = null;
  managementPlanNote = '';
  loading = false;

  // Services
  encounterService = new EncounterService();
  managementPlanService = new ManagementPlanService(this.patient.id);
  documentTemplateService = new DocumentTemplateService();
  patientDocumentService = new PatientDocumentService(this.patient.id);
  formSubmissionService = new PatientFormSubmissionService(this.patient.id);
  codeableConceptService = new CodeableConceptService();

  participantTypes: CodeableConcept[] = [];

  schema: FormConfig = {
    id: '',
    name: '',
    sections: [],
    completion: []
  };

  successMsg = '';
  errorMsg = '';
  success = false;
  error = false;
  smartForm = useSmartFormStore();


  notificationStore = useNotificationStore();

  get patientInfos() {
    return this.patient
      ? {
        [this.$t(
          'platform.common.name'
        ) as string]: `${this.patient.first_name} ${this.patient.last_name}`,
        [this.$t('platform.patient.dob') as string]: this.formatDob(
          this.patient.date_of_birth
        ),
        MRN: this.episodeOfCare.patient_mrn_at_clinic_provider
      }
      : {};
  }

  get clinicSummary() {
    const reviewerCode = this.findCodeableConceptByCode('REV', this.participantTypes);
    const reviewer = this.virtualEncounter?.participants.find(
      (participant) => participant.type_id === reviewerCode?.id
    );
    const clinicAddress = this.episodeOfCare.ophthalmology_details.clinic.provider?.address?.formatted_address;
    const clinic = this.episodeOfCare.ophthalmology_details.clinic.name;
    const dateCompleted = this.ambulatoryEncounter ? this.formatDate(
      this.ambulatoryEncounter.statuses.filter((status) => status.end === null)[0].start
    ) : '';
    return {
      [this.$t('custom.uhb.consult.clinic') as string]: clinic,
      [this.$t('custom.uhb.review.date-completed') as string]: dateCompleted,
      [this.$t('custom.uhb.consult.clinician') as string]: reviewer?.user?.name,
      ...(clinicAddress ? { [this.$t('platform.patient.location') as string]: clinicAddress } : {})
    };
  }

  get reviewSummary() {
    const completeAt = this.virtualEncounter?.statuses.filter((status) => status.end === null)[0].start;
    return {
      [this.$t('custom.uhb.review.date-completed') as string]: completeAt ? this.formatDate(completeAt) :
        this.$t('custom.uhb.review.not-complete-date')
    };
  }

  get formatContext() {
    let result = '';
    this.loading = true;
    // Patient Info
    result += this.formatSummary(
      this.$t('custom.uhb.review.summary.patient-summary') as string,
      this.patientInfos
    );
    if (this.virtualEncounter) {
      // Clinic Summary
      result += this.formatSummary(
        this.$t('custom.uhb.review.summary.clinic-summary') as string,
        this.clinicSummary
      );

      // Review Summary
      result += this.formatSummary(
        this.$t('custom.uhb.review.summary.summary') as string,
        this.reviewSummary
      );
    }

    // Form
    if (this.schema) {
      result += this.formatForm();
    }
    // Management Plan
    if (this.managementPlan) {
      result += this.formatManagementPlan();
    }
    // Export date
    result += `<br><br>Export generated on : ${this.today} via Big Picture Platform`;
    this.loading = false;
    return result;
  }

  get today() {
    return `${dayjs.utc().format('DD MMM YYYY, HH:mm:ss')}(UTC)`;
  }

  beforeCreate() {
    this.successMsg = this.$t('custom.uhb.review.summary.success-message');
    this.errorMsg = this.$t('custom.uhb.review.summary.error-message');
  }

  // TODO: move these functions into a reusable location
  async getCodeableConceptByCategory(category: string): Promise<CodeableConcept[]> {
    return (
      await this.codeableConceptService.index({
        params: {
          'filter[category]': category
        }
      })
    ).data;
  }

  // TODO: move these functions into a reusable location
  findCodeableConceptByCode(code: string, codeableConcepts: CodeableConcept[]) {
    return codeableConcepts.find((codableConcept) => codableConcept.code === code);
  }

  // TODO: move these functions into a reusable location
  async fetchCodeableConceptsByCategory(category: string, errorMessage: string) {
    let codeableConcepts;
    try {
      this.loading = true;
      codeableConcepts = await this.getCodeableConceptByCategory(category);
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: errorMessage,
        label: error.response?.data?.message || ''
      });
    } finally {
      this.loading = false;
    }

    return codeableConcepts;
  }

  fetchEncounterByClass(classId?: string): EpisodeOfCareEncounter | undefined {
    return this.episodeOfCare.encounters.find((e) => e.class === classId);
  }

  async mounted() {
    try {
      const encounterClasses = await this.fetchCodeableConceptsByCategory(
        codeableConceptCategories.encounterClass,
        this.$t('custom.uhb.episode-of-care.encounter-class-fetch-error')
      );

      this.participantTypes = await this.fetchCodeableConceptsByCategory(
        codeableConceptCategories.participantType,
        this.$t('custom.uhb.episode-of-care.participant-type-fetch-error')
      );

      const ambulatoryClassCode = this.findCodeableConceptByCode('AMB', encounterClasses);
      this.ambulatoryEncounter = this.fetchEncounterByClass(ambulatoryClassCode?.id);
      this.formSubmissionId = this.ambulatoryEncounter?.form_submission_id ?? '';

      // Set the virtual encounter
      const virtualClassCode = this.findCodeableConceptByCode('VR', encounterClasses);
      const encounter = this.fetchEncounterByClass(virtualClassCode?.id);
      if (encounter) {
        this.virtualEncounter = await this.encounterService.fetch(encounter.id);
      }

      await this.fetchManagementPlan();
      await this.fetchFormSubmission();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async fetchManagementPlan() {
    if (this.virtualEncounter?.plan_id) {
      this.managementPlan = (
        await this.managementPlanService.getManagementPlan(
          this.virtualEncounter.plan_id
        )
      ).data;
      if (this.managementPlan) {
        this.managementPlanNote = this.managementPlan.review_summary_markdown;
      }
    }
  }

  async fetchFormSubmission() {
    const formSubmission = await this.formSubmissionService.fetch(this.formSubmissionId);
    if (formSubmission.form?.schema) {
      this.schema = formSubmission.form.schema;
      this.smartForm.answers = {
        ...this.smartForm.answers,
        [this.formSubmissionId]: formSubmission.answers
      };
    }
  }

  conditionsMet(section: FormSection | FormItem) {
    return this.smartForm.getItemConditionsMet(
      this.formSubmissionId,
      section.conditions
    );
  }

  formatDob(isoDate: string) {
    return this.$d(getDobFromISOString(isoDate), 'short');
  }

  formatSummary(title: string, params: { [key: string]: any }) {
    let result = '';
    result += this.formatTitle(title);
    if (title && params) {
      for (const [key, value] of Object.entries(params)) {
        result += `<br>${key}: ${value}`;
      }
    }
    return result;
  }

  formatForm() {
    let result = '';
    if (this.schema.sections.length > 0) {
      result += this.formatTitle(
        `${this.schema.name} (${this.$t('platform.form.type')})`
      );
      for (let i = 0; i < this.schema.sections.length; i++) {
        if (
          this.conditionsMet(this.schema.sections[i]) &&
          this.schema.sections[i].heading &&
          !this.schema.sections[i].hide_when_readonly
        ) {
          result += this.formatSection(this.schema.sections[i]);
        }
      }
    }
    return result;
  }

  formatSection(section: FormSection) {
    let result = '';
    if (section.heading) {
      result += this.formatTitle(section.heading);
    }
    if (section.items) {
      result += this.formatItems(section.items);
    }
    return result;
  }

  formatItems(items: Array<FormItem>) {
    let result = '';
    for (let i = 0; i < items.length; i++) {
      result += this.formatItem(items[i]);
    }
    return result;
  }

  formatItem(item: FormItem) {
    let result = '';
    if (this.conditionsMet(item) && item.type !== 'hidden' && item.label) {
      if (
        item.type === 'select' ||
        item.type === 'radio' ||
        item.type === 'checkbox' ||
        item.type === 'slider'
      ) {
        if (item.type === 'checkbox' || item.type === 'radio') {
          // Multiselect use array for answer.
          result += `${this.formatItemLabel(item.label)}: ${this.answer(
            item.id
          )}`;
        } else if (item.type === 'slider' && item.props) {
          // BaseSlider use array index for answer.
          result += `${this.formatItemLabel(item.label)}: ${
            item.props.options[this.answer(item.id)]
          }`;
        } else {
          // BaseSelect use option id for answer.
          const optionId = this.answer(item.id);
          const option =
            item.props &&
            item.props.options.find(
              (option: IOption) => option.value === optionId
            );
          result += `${this.formatItemLabel(item.label)}: ${
            option ? option.label : this.$t('platform.common.not-answered')
          }`;
        }
      } else if (item.type === 'repeater') {
        // Loop the repeater group and display all the answers
        result += this.formatRepeaterQuestions(item);
      } else if (item.type === 'group') {
        // If type is group, just display label.
        result += this.formatItemLabel(item.label);
      } else if (item.type === 'component') {
        result += this.formatMedication(item);
      } else {
        result += `${this.formatItemLabel(item.label)}: ${this.answer(
          item.id
        )}`;
      }
    }

    if (item.type === 'list-item') {
      result += this.formatListItem(item);
    }

    if (item.items && item.type !== 'repeater') {
      result += this.formatItems(item.items);
    }
    return result;
  }

  formatManagementPlan() {
    let result = '';
    if (this.managementPlan && this.managementPlan.actions.length > 0) {
      for (let i = 0; i < this.managementPlan.actions.length; i++) {
        if (this.virtualEncounter?.extension?.unable_to_diagnose) {
          result += this.formatTitle(
            this.$t('custom.uhb.diagnosis.unable') as string
          );
          if (this.virtualEncounter.technical_issues.length) {
            result += '<br>Technical issues: ';
            for (let j = 0; j < this.virtualEncounter.technical_issues.length; j++) {
              result += `<br>${this.virtualEncounter.technical_issues[j].name}`;
            }
          }
        } else {
          result += this.formatTitle(
            this.$t('custom.uhb.diagnosis.diagnosis') as string
          );
        }
        const diagnoses = this.managementPlan.actions[i].diagnoses;
        const steps = this.managementPlan.actions[i].steps;
        if (diagnoses) {
          for (let j = 0; j < diagnoses.length; j++) {
            result += `<br>${diagnoses[j].anatomy.name}`;
            result += `<br> ${
              diagnoses[j].is_present
                ? diagnoses[j].condition.name
                : this.$t('custom.uhb.diagnosis.no-evidence-of', [
                  diagnoses[j].condition.name
                ])
            }`;
            result += diagnoses[j].stage
              ? ` (${diagnoses[j].stage?.name || ''}) `
              : '';
            result += diagnoses[j].status
              ? `[${diagnoses[j].status?.name || ''}]`
              : '';
          }
        }
        result +=
          steps && steps.length ? this.formatManagementPlanSteps(steps) : '';
      }
    }

    if (this.managementPlanNote) {
      result += this.formatTitle(
        this.$t('custom.uhb.management.notes') as string
      );
      result += `<br>${this.managementPlanNote}<br>`;
    }
    return result;
  }

  formatTitle(title: string) {
    return `<br><br><b>${title}</b>`;
  }

  formatItemLabel(label: string) {
    return `<br><br>${label}`;
  }

  formatManagementPlanSteps(steps: Array<ManagementPlanStep>) {
    let result = this.formatTitle(
      this.$t('custom.uhb.management.plan') as string
    );
    for (let i = 0; i < steps.length; i++) {
      result += `<br>${this.formatManagementPlanStep(steps[i])}`;
    }
    return result;
  }

  formatManagementPlanStep(step: ManagementPlanStep) {
    const timeframe =
      step.timeframe.name === 'None'
        ? `[${this.$t('platform.common.none')}] `
        : `[${step.timeframe.name}] `;
    const notes = step.notes
      ? ` (${this.$t('platform.common.notes')}:${step.notes})`
      : '';
    return timeframe + step.destination.name + notes;
  }

  formatRepeaterQuestions(item: FormItem) {
    let result = '';
    const repeaterAnswers = this.answer(item.id);
    if (typeof repeaterAnswers === 'string' && item.items) {
      for (let i = 0; i < item.items.length; i++) {
        if (item.items[i].label) {
          result += `<br><br>${item.items[i].label}: ${repeaterAnswers}`;
        } else if (
          repeaterAnswers !== this.$t('platform.common.not-answered')
        ) {
          result += `<br><br>${repeaterAnswers}`;
        }
      }
    } else {
      for (let i = 0; i < Object.keys(repeaterAnswers).length; i++) {
        const repeaterAnswer = repeaterAnswers[i];
        const itemIdsOfAnswer = Object.keys(repeaterAnswer);
        for (let j = 0; j < itemIdsOfAnswer.length; j++) {
          const currentAnswerItemId = itemIdsOfAnswer[j];
          const itemOfRepeaterAnswer =
            item.items &&
            item.items.find(
              (item: FormItem) => item.id === currentAnswerItemId
            );
          if (itemOfRepeaterAnswer) {
            let label = itemOfRepeaterAnswer.label;
            if (itemOfRepeaterAnswer.type === 'date') {
              label = this.$t('platform.common.date');
            }
            if (itemOfRepeaterAnswer.type === 'text') {
              label = this.$t('custom.uhb.diagnosis.condition');
            }
            result += `<br><br>${label}: ${
              repeaterAnswers[i][currentAnswerItemId]?.value
            }`;
          }
        }
      }
    }
    return result.length ? `<br><br>${item.label}${result}` : '';
  }

  formatMedication(item: FormItem) {
    let result = '';
    const answers = this.smartForm.getAnswer(
      this.formSubmissionId,
      item.id
    );
    if (!answers) {
      return this.$t('platform.common.not-answered');
    }
    result += `<br><br>${item.label}`;
    for (let i = 0; i < Object.keys(answers).length; i++) {
      for (let j = 0; j < Object.keys(answers[i]).length; j++) {
        const answerKey = Object.keys(answers[i])[j];
        const answerValue = answers[i][Object.keys(answers[i])[j]];
        result += `<br>`;
        if (answerKey === 'medication') {
          result += `${this.$t('platform.common.name')}: ${answerValue?.name?.value ?? this.$t('platform.common.not-answered')}`;
        } else {
          result += `${answerKey}: ${answerValue?.value ?? this.$t('platform.common.not-answered')}`;
        }
      }
    }
    return result;
  }

  formatListItem(item: FormItem) {
    let result = '';

    const answers = this.smartForm.getAnswer(
      this.formSubmissionId,
      item.id
    );

    result += `<br>${item.props?.label}: ${answers[0]}`;

    if (!answers[0]) {
      result += ` - ${answers[1]}`;
    }

    return result;
  }

  answer(itemId: string) {
    const answer = this.smartForm.getAnswer(
      this.formSubmissionId,
      itemId
    );

    if (typeof answer === 'undefined') {
      return this.$t('platform.common.not-answered');
    } else if (answer === true) {
      return this.$t('platform.common.yes') as string;
    } else if (answer === false) {
      return this.$t('platform.common.no') as string;
    }

    return answer;
  }

  showSuccess() {
    this.success = true;
    this.error = false;
    this.$emit('copy', true);
  }

  showError() {
    this.success = false;
    this.error = true;
    this.$emit('copy', false);
  }

  formatDate(date: string) {
    return dayjs(date).format('DD MMM YYYY, [at] hh:MM:ss a');
  }

  close() {
    this.$emit('close');
  }
}
