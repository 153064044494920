import { GenericService } from './generic.service';
import { RoleAssignment, User } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class OrganisationUserService extends GenericService<User> {
  constructor(organisationId: string) {
    super();
    this.endpoint = `v1/organisations/${organisationId}/users`;
  }

  async getRoles(userId: string, params: AxiosRequestConfig = {}): Promise<AxiosResponse<Array<RoleAssignment>>> {
    return await apiClient.get(`${this.endpoint}/${userId}/roles`, params);
  }

  async setRoles(userId: string, ids: Array<number>, params: AxiosRequestConfig = {}): Promise<User> {
    return await apiClient.put(`${this.endpoint}/${userId}/role-assignments`, { ids }, params);
  }

  async addUser(userId: string): Promise<AxiosResponse> {
    return await apiClient.put(`${this.endpoint}/${userId}`);
  }

  async resetMFA(userId: string, params: AxiosRequestConfig = {}): Promise<{ message: string }> {
    return await apiClient.post(`${this.endpoint}/${userId}/reset-mfa`, params);
  }

  async resetPassword(userId: string, params: AxiosRequestConfig = {}): Promise<{ message: string }> {
    return await apiClient.post(`${this.endpoint}/${userId}/reset-password`, params);
  }

  async updateAccountSetting(userId: string, params: Partial<User>): Promise<AxiosResponse<User>> {
    return await apiClient.patch(`${this.endpoint}/${userId}/account-setting`, params);
  }

  async resendInvite(userId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.post(`${this.endpoint}/${userId}/resend-invite`, params);
  }

  async updateProfile(
    userId: string,
    changes: Partial<User>,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<User>> {
    return await apiClient.patch(`${this.endpoint}/${userId}/profile`, changes, params);
  }
}
