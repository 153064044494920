import { Patient } from '@/models/patient.model';
import { Review } from '@/models/review.model';
import { FormSubmission } from '@/models/form-submission.model';
import { Media } from '@/models/media.model';
import { Organisation } from '@/models/organisational-unit.model';
import { Status } from '@/models/patient-consult.model';
import { Form } from '@/models/form.model';
import { TriageStatus } from '@/models/triage.model';

export interface Consult {
  id: string;
  patient_id: string;
  clinic_id: string;
  clinic_configuration_id: string;
  patient?: Patient;
  clinic: Clinic;
  clinic_configuration?: ClinicConfiguration;
  created_at?: string;
  review?: Review;
  consultationId?: string;
  form_submission?: FormSubmission;
  clinician?: string;
  completed_at?: string;
  scheduled_at?: string;
  rejected_at?: string;
  rejected_reason?: string;
  rejected_reason_type?: string;
  resolve_reason?: string;
  resolve_reason_type?: string;
  resolved_rejected_at?: string;
  scheduled_location?: string;
  patient_mrn: string;
  patient_mrn_at_clinic_provider?: string;
  patient_mrn_at_clinic_owner?: string;
  media: Array<Media>;
  consult_type?: ConsultType;
  status: Status;
  triage_status_id?: string;
  triage_status?: TriageStatus;
}

export interface Clinic {
  id: string;
  name: string;
  code?: string;
  clinic_configuration_id: string;
  provider?: Organisation;
  owner?: Organisation;
}

export interface BPMLocation {
  id: string;
  name: string;
}

export interface ClinicConfiguration {
  id: string;
  name: string;
  forms: Array<Form>;
}

export interface ConsultType {
  id: string;
  name: string;
}

export interface RejectReasonValue {
  selectedReason: string;
  additionalReason: string;
}

export interface ResolveReasonValue {
  selectedReason: string;
  additionalReason: string;
}

export const RESOLVE_REASON_MAX_LENGTH = 500;

export const REJECT_REASON_MAX_LENGTH = 500;

export enum ConsultTabStatus {
  ALL_CONSULTS,
  UNSCHEDULED,
  SCHEDULED,
  CONSULTING,
  REVIEWING,
  REJECTED
}
