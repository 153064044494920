import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled", "value", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!

  return (_openBlock(), _createBlock(_component_BaseTooltip, {
    position: "above",
    disabled: !_ctx.title.length || _ctx.loading
  }, {
    target: _withCtx(() => [
      _createElementVNode("label", {
        class: _normalizeClass(_ctx.style.label)
      }, [
        _createElementVNode("input", _mergeProps({
          type: "radio",
          class: _ctx.style.radio,
          disabled: _ctx.disabled || _ctx.loading
        }, _ctx.$attrs, {
          value: _ctx.value,
          checked: _ctx.checked,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange(_ctx.value)))
        }), null, 16, _hoisted_1),
        _createElementVNode("span", {
          class: _normalizeClass(["text-base", { 'text-transparent bg-gray-200 rounded-lg h-5': _ctx.loading }])
        }, _toDisplayString(_ctx.label), 3)
      ], 2)
    ]),
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    _: 1
  }, 8, ["disabled"]))
}