import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["name", "disabled", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["rounded inline-flex items-center transition-all duration-150 cursor-pointer", [
      {
        'border border-danger-700': _ctx.error && !_ctx.disabled,
        'border border-solid border-gray-300 bg-gray-100 cursor-not-allowed': _ctx.disabled,
        'bg-white border-gray-300 border border-solid shadow hover:shadow-md hover:border-gray-400':
          !_ctx.disabled && !_ctx.dark && (_ctx.border || _ctx.shadow),
        'border border-solid bg-white border-primary-500 outline-primary-500':
          _ctx.checked && !_ctx.disabled && !_ctx.loading && !_ctx.error && !_ctx.dark && (_ctx.border || _ctx.shadow),
        'bg-danger-500 shadow': _ctx.checked && !_ctx.disabled && _ctx.dark,
        'bg-white outline-none': !_ctx.border && !_ctx.shadow,
        'shadow-none': !_ctx.shadow,
        'border-none': !_ctx.border,
        'border-gray-800 border border-solid shadow hover:shadow-md hover:border-gray-400 bg-gray-900':
          !_ctx.disabled && _ctx.dark
      },
      _ctx.isHovering ? _ctx.hoverBgClass : ''
    ]]),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHovering = true)),
    onMouseout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHovering = false))
  }, [
    _createElementVNode("input", _mergeProps({
      ref: "checkbox",
      class: ["form-checkbox outline-none", [
        _ctx.disabled || _ctx.loading ? 'cursor-not-allowed' : 'cursor-pointer',
        _ctx.dark ? 'text-danger-900 bg-gray-800' : '',
        _ctx.checkboxClass
      ]],
      type: "checkbox",
      name: _ctx.value
    }, _ctx.$attrs, {
      disabled: _ctx.disabled || _ctx.loading,
      checked: _ctx.checked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args)))
    }), null, 16, _hoisted_1),
    (!_ctx.$slots.customLabel)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["p-3 text-base", [{ 'text-white': _ctx.dark, 'text-transparent bg-gray-200 rounded-lg h-5 m-3': _ctx.loading }, _ctx.textClass]])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "customLabel")
  ], 34))
}