
import { Vue, Options } from 'vue-class-component';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';
import { Organisation, Patient } from '@/models';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import NavDropdownButton from '@/lib/components/Navigation/NavDropdownButton.vue';
import NavLink from '@/lib/components/Navigation/NavLink.vue';
import BaseBanner from '@/lib/components/Banner/BaseBanner.vue';
import { FEATURES, sexOptions, genderOptions } from '@/constants';
import { BasePopover, PopoverLink } from '@/lib/components/Popover';
import { usePatientStore } from '@/stores/patient.store';

@Options({
  components: {
    BaseIcon,
    NavDropdownButton,
    BasePopover,
    PopoverLink,
    NavLink,
    ButtonLink,
    BaseBanner
  },

  props: {
    patient: {
      type: Object,
      required: true
    },
    organisation: {
      type: Object,
      required: true
    }
  }
})
export default class SingleNavigation extends Vue {
  patientStore = usePatientStore();
  organisation!: Organisation;
  patient!: Patient;
  $customer?: string;
  familyHistoryFeatureFlag = FEATURES.FAMILY_HISTORY_CLINICAL_BLOCK;
  smokingSummaryFeatureFlag = FEATURES.SMOKING_SUMMARY_CLINICAL_BLOCK;
  virtualDoctorFeatureFlag = FEATURES.VIRTUAL_DOCTOR;
  clinicalBlocksFeatureFlag = FEATURES.CLINICAL_BLOCKS;
  patientRecordPathwaysFeatureFlag = FEATURES.PATIENT_RECORD_PATHWAYS;

  medicationsAndAllergiesMenuItems = [
    {
      type: 'link',
      routeName: 'blocks-medications-statement',
      i18nKey: 'projects.clinical-blocks.medications-statement'
    },
    {
      type: 'divider'
    },
    {
      type: 'link',
      routeName: 'blocks-allergies',
      i18nKey: 'projects.clinical-blocks.allergies'
    }
  ];

  familyAndSocialHistoryMenuItems = [
    {
      type: 'link',
      routeName: 'blocks-family-history',
      i18nKey: 'projects.clinical-blocks.family-history'
    },
    {
      type: 'divider'
    },
    {
      type: 'link',
      routeName: 'blocks-tobacco-smoking-summary',
      i18nKey: 'projects.clinical-blocks.tobacco-smoking-summary'
    },
    {
      type: 'link',
      routeName: 'blocks-alcohol-consumption-summary',
      i18nKey: 'projects.clinical-blocks.alcohol-consumption-summary'
    }
  ];

  devLabMenuItems = [
    {
      type: 'link',
      routeName: 'blocks-management-plan',
      i18nKey: 'projects.clinical-blocks.management-plan'
    },
    {
      type: 'divider'
    },
    {
      type: 'link',
      routeName: 'blocks-presenting-complaint',
      i18nKey: 'projects.clinical-blocks.presenting-complaint'
    }
  ];

  get sex() {
    return sexOptions().find((option) => option.value === this.patient.sex);
  }

  get copdHomeMonitoringProgramId(): string | null {
    return this.patient.copd_home_monitoring_program?.id || null;
  }

  get gender() {
    return genderOptions().find((option) => option.value === this.patient.gender);
  }

  get useGender() {
    return this.organisation?.uses_gender && this.patient.gender;
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  get nhsNumberFormatted(): string {
    const nhsNumber = getNhsNumberFromPatient(this.patient);
    return nhsNumber || (this.$t('platform.common.none') as string);
  }

  get copdIsEnabled() {
    return this.$customer === 'UHB';
  }

  formatDate(date: string | null, type = 'short'): string {
    return date ? this.$d(getDobFromISOString(date), type) : '';
  }
}
