
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BaseButton } from '@/lib/components/Button';
import { FormConfig, FormSection } from '@/models';
import { i18n } from '@/i18n/i18n';
import FormSummarySection from '@/custom/menicon/components/encounter-summary/FormSummarySection.vue';
import { useSmartFormStore } from '@/stores/smartForm.store';

dayjs.extend(utc);

@Options({
  props: {
    config: {
      type: Object,
      required: true
    },

    participantId: {
      type: String,
      default: null
    },

    patientId: {
      type: String,
      default: null
    },

    formSubmissionId: {
      type: String,
      required: true
    },

    date: {
      type: String,
      required: true
    },

    userName: {
      type: String,
      default: ''
    },

    locale: {
      type: String,
      default: i18n.global.locale.value
    }
  },
  components: {
    FormSummarySection,
    BaseButton
  }
})
export default class SmartFormSummary extends Vue {
  config!: FormConfig;
  locale!: string;
  formSubmissionId!: string;
  smartForm = useSmartFormStore();

  get activeSections(): Array<FormSection> {
    return this.config.sections.filter(
      (section: FormSection) =>
        this.smartForm.getItemConditionsMet(this.formSubmissionId, section.conditions) &&
        !section.hide_when_readonly &&
        section.name
    );
  }

  formatDate(date: string) {
    return this.$d(new Date(date), 'shortMonth', this.locale);
  }
}
