import { Customer, CustomerBranding, Feature, Organisation, Permission, User } from '@/models';
import { defineStore } from 'pinia';
import { CustomersService, UserService, UsersService } from '@/services/api';
import { getDefaultLocale, i18n, switchLocale } from '@/i18n/i18n';
import { v4 } from 'uuid';
import { IdlesService } from '@/services';
import { IdleJs } from '@sbourouis/idle-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';

dayjs.extend(utc);
const userService = new UserService();
const usersService = new UsersService();
const customersService = new CustomersService();

interface State {
  currentUser: User | null;
  currentOrganisationId: string | null;
  customerId: string | null;
  adminCustomers: Customer[];
  adminCustomer: Customer | null;
  token: string | null;
  refreshToken: string | null;
  expireDate: string | null;
  isMaintenance: boolean;
  uuid: string | null;
  isIdle: boolean;
  idle: any | null;
}

const initialState: State = {
  currentUser: null,
  customerId: null,
  token: null,
  refreshToken: null,
  adminCustomers: [],
  adminCustomer: null,
  expireDate: null,
  isMaintenance: false,
  isIdle: false,
  idle: null,
  uuid: null,
  currentOrganisationId: null
};

export const useSessionStore = defineStore('session', {
  state: (): State => ({
    ...initialState
  }),
  getters: {
    policiesToAgreeTo(): Customer[] {
      const policies = [];
      const userAgreedToAdminCustomerPolicy = this.currentUser?.accepted_customers
        .find((acceptedCustomer) => acceptedCustomer.id === this.adminCustomer?.id);
      if (this.adminCustomer && !userAgreedToAdminCustomerPolicy) {
        policies.push(this.adminCustomer);
      }
      if (this.currentUser?.is_admin) {
        const adminUnacceptedPolicies = this.adminCustomers
          .filter((customer) => customer.id !== this.adminCustomer?.id && customer.has_terms_of_service &&
          !this.currentUser?.accepted_customers.find((acceptedCustomer) => acceptedCustomer.id === customer.id)
          );
        return [...policies, ...adminUnacceptedPolicies];
      } else if (this.customerId && this.currentOrganisation?.domain.customer.has_terms_of_service &&
        !this.currentUser?.accepted_customers
          .find((acceptedCustomer) => acceptedCustomer.id === this.currentOrganisation.domain.customer.id)) {
        return [...policies, this.currentOrganisation.domain.customer];
      }
      return policies;
    },
    currentOrganisation: (state: State): Organisation | null => state.currentUser?.organisations
      .find((organisation) => organisation.id === state.currentOrganisationId) ?? null,
    getOrganisation: (state: State) => (organisationId: string): Organisation | null => state.currentUser?.organisations
      .find((organisation) => organisation.id === organisationId) ?? null,
    permissions(): string[] {
      if (this.currentOrganisation?.permissions) {
        return this.currentOrganisation.permissions.map((permission: Permission) => permission.key);
      }
      return [];
    },
    features(): Feature[] {
      return [
        ...(this.currentUser?.features ?? []),
        ...(this.currentOrganisation?.domain?.features ?? [])
      ];
    },
    legalRegions(): string[] {
      const regions = this.currentOrganisation?.domain?.customer.legal_regions;
      return regions?.length ? regions : [DEFAULT_LEGAL_REGION];
    },
    customerBranding(): CustomerBranding {
      return this.currentOrganisation?.domain?.customer.branding ?? CustomerBranding.BPM;
    },
    customerFromUrl(): Customer[] {
      return this.customers.filter((customer) => window.location.origin === customer.url) ?? null;
    },
    organisations(): Organisation[] {
      return this.customerId ? this.currentUser?.organisations
        .filter((organisation) => organisation.domain?.customer?.id === this.customerId)
        .sort((a: Organisation, b: Organisation) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) : [];
    },
    customers: (state: State): Customer[] => {
      const userCustomers = state.currentUser?.organisations.reduce((customers, organisation) => {
        const customer = organisation.domain?.customer;
        return {
          ...customers,
          ...(customer ? { [customer.id]: customer } : {})
        };
      }, {} as Record<string, Customer>) ?? {};

      return Object.values(userCustomers);
    }
  },
  actions: {
    async login(credential) {
      const response = await userService.login(credential);
      this.token = response.access_token;
      this.refreshToken = response.refresh_token;
      this.setExpireDate(response.expires_in);
      return response;
    },
    async fetchAdminCustomer() {
      this.adminCustomer = (await customersService.index({
        params: {
          'filter[is_admin]': '1'
        }
      })).data?.[0] ?? null;
    },
    setCurrentOrganisationId(organisationId: string | null) {
      this.currentOrganisationId = organisationId;
      window.localStorage.setItem('organisationId', organisationId);
      if (organisationId) {
        this.customerId = this.currentOrganisation?.domain?.customer?.id ?? null;
      } else if (!this.customerFromUrl.find((customer) => customer.id === this.customerId)) {
        this.customerId = this.customerFromUrl[0]?.id ?? null;
      }
    },
    setCurrentOrganisationIdForCustomer(customerId: string | null) {
      if (customerId) {
        const organisationId = this.currentUser?.organisations
          ?.find((organisation) => organisation.domain?.customer?.id === customerId)?.id ?? null;
        this.setCurrentOrganisationId(organisationId);
      } else {
        this.setCurrentOrganisationId(null);
      }
    },
    async refreshUserToken(params) {
      const response = await userService.refreshToken(params);
      this.token = response.access_token;
      this.refreshToken = response.refresh_token;
      this.setExpireDate(response.expires_in);
      return response;
    },
    setExpireDate(expiresIn: number) {
      this.expireDate = dayjs.utc().add(expiresIn, 'second').format('YYYY-MM-DD HH:mm:ss');
    },
    async revokeUserToken(params) {
      return await userService.revokeToken(params);
    },
    async fetchUserOTP(params) {
      return await userService.mfaAssociate(params);
    },
    async fetchUserSms(params) {
      return await userService.mfaAssociate(params);
    },
    async fetchUserSmsChallenge(params) {
      return await userService.mfaChallenge(params);
    },
    async fetchCurrentUser() {
      try {
        const user = await userService.getCurrent({
          params: {
            append: 'actionable_domain_ids'
          }
        });
        this.setCurrentUser(user);
        if (user.is_admin) {
          this.adminCustomers = await usersService.getUserCustomers(user.id);
        }

        const locale = user.locale;
        if (locale) {
          switchLocale(locale);
        }
        if (!this.currentOrganisationId && !this.customerId) {
          const savedOrganisationId = window.localStorage.getItem('organisationId');

          if (!savedOrganisationId) {
            this.setCurrentOrganisationIdForCustomer(this.customerFromUrl[0]?.id);
            return;
          }

          const organisationInResponse = user.organisations.find(
            (organisation: Organisation) => organisation.id === savedOrganisationId &&
              organisation.domain?.customer?.id === this.customerFromUrl[0]?.id
          );

          if (organisationInResponse) {
            this.setCurrentOrganisationId(organisationInResponse?.id ?? null);
          } else {
            this.setCurrentOrganisationIdForCustomer(this.customerFromUrl[0]?.id);
          }
        }
      } catch (e) {
        // TODO: maybe handle the error properly? Show a notification, a snackbar? to be decided
        console.error(e);
      }
    },
    async saveUser(user) {
      const response = await userService.updateCurrent(user);
      this.setCurrentUser({
        ...(this.currentUser ? this.currentUser : {}),
        ...response
      });
      return response;
    },
    updateCurrentUser(user: Partial<User>) {
      this.currentUser = {
        ...(this.currentUser ? this.currentUser : {}),
        ...user
      };
    },
    setCurrentUser(user: User | null) {
      this.currentUser = user;

      if (user) {
        if (user.locale !== i18n.global.locale.value) {
          switchLocale(user.locale);
        }
        if (this.idle) {
          this.idle.stop();
        }
        const idleTime = user.inactivity_timeout_seconds * 1000;
        const idle = new IdleJs({
          idle: idleTime, // idle time in ms
          onIdle: () => {
            this.isIdle = true;
          },
          keepTracking: true, // set it to false if you want to be notified only on the first idleness change
          startAtIdle: true // set it to true if you want to start in the idle state
        });
        this.isIdle = false;
        this.idle = idle;
        idle.start();

        if (!this.uuid) {
          const id = v4();
          this.uuid = id;
          IdlesService.addIdle(id);
        } else if (!IdlesService.hasIdle(this.uuid)) {
          IdlesService.addIdle(this.uuid);
        }
      } else {
        Object.assign(this, initialState);
        switchLocale(getDefaultLocale());
      }
    }
  }
});
