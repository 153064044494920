import router from '@/router';
import { useSessionStore } from '@/stores/session.store';
// @ts-ignore
import { watch } from 'vue';

const directive = {
  mounted: (el, binding) => {
    const removeChild = document.createComment(' ');
    const sessionStore = useSessionStore();
    const { currentUser } = sessionStore;
    if (!(router.currentRoute.value.meta?.isAdmin && currentUser?.is_admin)) {
      if (binding.value?.length && !sessionStore.permissions.includes(binding.value)) {
        el.parentNode?.replaceChild(removeChild, el);
      } else if (removeChild.parentNode) {
        removeChild.parentNode.replaceChild(el, removeChild);
      }
    }
    el.unwatch = watch(() => sessionStore.currentOrganisationId, () => {
      if (!(router.currentRoute.value.meta?.isAdmin && currentUser?.is_admin)) {
        if (binding.value?.length && !sessionStore.permissions.includes(binding.value)) {
          el.parentNode?.replaceChild(removeChild, el);
        } else if (removeChild.parentNode) {
          removeChild.parentNode.replaceChild(el, removeChild);
        }
      }
    });
  },
  unmounted: (el) => {
    if (el.unwatch) {
      el.unwatch();
    }
  }
};

export default directive;
