
import { Vue, Options } from 'vue-class-component';
import { v4 } from 'uuid';
import { ICheckboxOption, IOption } from '@/lib';
import BaseCheckbox from '@/lib/components/Checkbox/BaseCheckbox.vue';
import BasePopover from '@/lib/components/Popover/BasePopover.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { InputTextHelper } from '@/lib/components/Helper';

@Options({
  components: { BaseIcon, BaseCheckbox, BasePopover, InputTextHelper },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array, // Array<{ value: string; label: string; disabled: boolean; }>
      default: () => []
    },
    error: {
      type: String,
      default: null
    },
    lineHeight: {
      type: Number,
      default: 1
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  }
})
export default class BaseSelectCheckbox extends Vue {
  id: string = v4();
  focused = false;
  selectedValues:ICheckboxOption[] = [];

  mounted() {
    if (this.isFilter) {
      this.$nextTick(() => {
        this.selectedValues = this.options.filter((option) => this.modelValue.includes(option.id))
          .map((option) => ({
            id: option.id,
            label: option.label,
            checked: true
          }));
      });
    }
  }

  get formattedValue() {
    const selectedOptions = this.options.filter((option: IOption) =>
      this.modelValue.includes(option.value)
    );
    return selectedOptions.map((o: IOption) => o.label).join(', ');
  }

  get checkboxModel() {
    return this.modelValue;
  }

  set checkboxModel(value) {
    this.$emit('update:modelValue', value);
  }

  get badgeNumber() {
    return this.selectedValues.length;
  }

  get placeholderText() {
    if (this.isFilter) {
      return this.placeholder;
    }
    return this.formattedValue || this.placeholder;
  }

  openPopup() {
    (this.$refs.selectCheckboxPopover as BasePopover).open();
  }

  updateModelArray(item:ICheckboxOption) {
    if (item.checked) {
      this.selectedValues.push(item);
    } else {
      this.selectedValues = this.selectedValues.filter(
        (it) => it.id !== item.id
      );
    }
    this.$emit('update:listObject', this.selectedValues);
  }

  clearAllSelections() {
    this.$refs.baseCheckbox.map((el) => {
      el.uncheck();
    });
    this.selectedValues = [];
  }

  focus() {
    this.focused = true;
  }

  blur() {
    this.focused = false;
  }
}
