
import { Options, Vue } from 'vue-class-component';
import { logoutService } from '@/services/auth';
import { Customer, Organisation } from '@/models';
import { BaseAvatar } from '@/lib/components/Avatar';
import SupportModal from '@/lib/components/Modals/SupportModal.vue';
import { BasePopover, PopoverButton, PopoverLink } from '@/lib/components/Popover';
import NavDropdownButton from './NavDropdownButton.vue';
import BrandLogo from '@/lib/components/Branding/BrandLogo.vue';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';

@Options({
  components: {
    BaseButton,
    BaseIcon,
    NavDropdownButton,
    BasePopover,
    PopoverButton,
    PopoverLink,
    SupportModal,
    BaseAvatar,
    BrandLogo
  },
  props: {
    displayOrganisationsList: {
      type: Boolean,
      default: true
    }
  }
})
export default class MainNavigation extends Vue {
  showSupportModal = false;
  logout = logoutService;
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get env() {
    return process.env.VUE_APP_ENV;
  }

  get isAdmin() {
    return this.$route.meta?.isAdmin ||
      (!this.sessionStore.currentOrganisationId && this.sessionStore.currentUser?.is_admin);
  }

  get user() {
    return this.sessionStore.currentUser;
  }

  get customers() {
    return this.sessionStore.customers;
  }

  get customerId() {
    return this.sessionStore.customerId;
  }

  get userName() {
    return {
      given_name: this.sessionStore.currentUser.given_name,
      family_name: this.sessionStore.currentUser.family_name
    };
  }

  get url(): string {
    return this.sessionStore.currentUser.avatar_url;
  }

  get currentOrganisation() {
    return this.sessionStore.currentOrganisation || {};
  }

  get organisations(): Array<Organisation> {
    return this.sessionStore.organisations || [];
  }

  accessDomainAdmin() {
    if (!this.isAdmin) {
      this.$router.push({ name: 'domain-admin' });
    }
  }

  accessCustomer(customer: Customer) {
    if (this.isAdmin && customer.id === this.customerId) {
      this.sessionStore.setCurrentOrganisationIdForCustomer(customer.id);
      this.$router.push({
        name: 'patient-list',
        params: {
          organisationId: this.sessionStore.currentOrganisationId
        }
      });
    } else if (customer.id !== this.customerId) {
      window.open(`${customer.url}?customer=${customer.id}`, '_blank');
    }
  }

  async switchOrganisation(organisation: Organisation) {
    if (this.currentOrganisation.id !== organisation.id || this.isAdmin) {
      this.sessionStore.setCurrentOrganisationId(organisation.id);
      this.notificationStore.snackbar = {
        label: this.$t('platform.org-unit.switch-message', [organisation.name]),
        autoDismiss: true,
        dismissAfter: 6000,
        color: 'success',
        icon: 'tick'
      };
      const routePermission = this.$route.meta?.permission;
      const permissions = this.sessionStore.permissions || [];
      // When we switch from domain admin to an org or
      // When we switch an org and we don't have the permission to access the page anymore
      // We should redirect the user
      if ((routePermission && !permissions.includes(routePermission)) || this.isAdmin) {
        if (this.$route.path.includes('account-settings')) {
          await this.$router.push({ name: 'settings' });
        } else if (this.$route.path.includes('organisation-settings')) {
          await this.$router.push({ name: 'organisation-settings', params: { organisationId: organisation.id } });
        } else if (permissions.includes('anonymous-participant:read')) {
          await this.$router.push({ name: 'participant-list', params: { organisationId: organisation.id } });
        } else if (permissions.includes('patient:read')) {
          await this.$router.push({ name: 'patient-list', params: { organisationId: organisation.id } });
        } else {
          await this.$router.push({ name: 'settings' });
        }
      } else {
        await this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params, organisationId: organisation.id }
        });
      }
    }
  }
}
