
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BaseIcon } from '@/lib/components/Icon';
import { ButtonLink } from '@/lib/components/Button';
import { DataTable } from '@/lib/components/DataTable';
import { BaseTooltip } from '@/lib/components/Tooltip';
import { BasePagination } from '@/lib/components/Pagination';
import { RowPatientSummary } from '@/lib/components/PatientSummary';
import {
  CopdHomeMonitoringProgramRow,
  CopdProgram,
  CopdProgramMeasurement,
  Patient,
  Organisation,
  CopdProgramReview,
  CopdRelationship,
  Tag,
  TagGroup
} from '@/models';
import kebabCase from 'lodash-es/kebabCase';
import { FEATURES } from '@/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { IColumn } from '@/lib';

dayjs.extend(utc);

@Options({
  components: {
    BaseIcon,
    BaseTooltip,
    RowPatientSummary,
    ButtonLink,
    DataTable,
    BasePagination
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    },
    sort: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    rows: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    tagGroups: {
      type: Array,
      default: () => []
    },
    patients: {
      type: Array,
      default: () => []
    },
    organisationalUnits: {
      type: Array,
      default: () => []
    },
    reviews: {
      type: Array,
      default: () => []
    },
    measurements: {
      type: Array,
      default: () => []
    }
  }
})
export default class CopdPatientsTable extends Vue {
  page!: number;
  sort!: string;
  organisationId!: string;

  rows!: CopdProgram[];
  tags!: Array<Tag>;
  tagGroups!: Array<TagGroup>;
  patients!: Patient[];
  reviews!: CopdProgramReview[];
  organisationalUnits!: Organisation[];
  measurements!: CopdProgramMeasurement[];
  perPage = 0;
  total = 0;
  columns: IColumn[] = [];

  // if Tags enabled orgUnit column switch to Teams
  get tagFeatureFlagEnabled(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }

  beforeCreate() {
    this.columns = [
      { name: 'last_sent', label: this.$t('custom.uhb.copd.last-sent') },
      { name: 'patient', label: this.$t('platform.patient.patient') },
      { name: 'status', label: this.$t('platform.common.status') },
      { name: 'actions', label: '', align: 'right' }
    ];
  }

  mounted() {
    const dynamicCol = this.tagFeatureFlagEnabled
      ? { name: 'team', label: this.$t('custom.uhb.copd.teams') }
      : {
        name: 'organisation',
        label: this.$t('platform.org-unit.org-unit')
      };
    this.columns.splice(2, 0, dynamicCol);
  }

  formatDate(date: string) {
    return dayjs(date).format('D MMM YYYY');
  }

  dischargedAt(row: CopdHomeMonitoringProgramRow) {
    const review = this.reviewItem(row);
    if (review) {
      const date = review.attributes.created_at;
      return this.formatDate(date);
    }
    return '';
  }

  dischargeKey(row: CopdHomeMonitoringProgramRow) {
    const review = this.reviewItem(row);
    if (review?.attributes.outcome === 'discharge') {
      return kebabCase(review?.attributes.reason as unknown as string);
    }
    return '';
  }

  statusColor(row: CopdHomeMonitoringProgramRow) {
    switch (this.status(row)) {
    case 'ready-for-review':
      return row.attributes.high_risk ? 'danger' : 'primary';
    case 'awaiting-measurements':
      return 'gray';
    case 'discharged':
      return 'alert';
    default:
      return 'gray';
    }
  }

  patient(row: CopdHomeMonitoringProgramRow): Patient | undefined {
    return this.patients.find(
      (patient) => patient.id === row.relationships.patient.id
    );
  }

  organisationalUnitName(row: CopdHomeMonitoringProgramRow): string {
    if (row.relationships.assigned_organisational_unit === null) {
      return '—';
    }
    return (
      this.organisationalUnits.find(
        (organisationalUnit) =>
          organisationalUnit.id ===
          row.relationships.assigned_organisational_unit.id
      )?.name || '—'
    );
  }

  getTagName(row, groupName): string {
    if (row.relationships.tags === null) {
      return '—';
    }

    let tagName = '-';
    if (groupName === 'community-team') {
      const groups = this.tagGroups.filter((group) => group.attributes.name === groupName);
      tagName = (this.tags.find(
        (tag) =>
          (row.relationships.tags as Array<CopdRelationship>).map((t) => t.id).includes(tag.id)
          && groups && groups.some((group) => tag.relationships?.group?.id === group.id)
      )?.attributes?.name || '—');
    } else {
      const group = this.tagGroups.find((group) => group.attributes.name === groupName);
      tagName = (this.tags.find(
        (tag) =>
          (row.relationships.tags as Array<CopdRelationship>).map((t) => t.id).includes(tag.id)
          && group && tag.relationships?.group?.id === group.id
      )?.attributes?.name || '—');
    }
    return tagName;
  }

  lastSentDate(row: CopdHomeMonitoringProgramRow): string {
    const measurement: CopdProgramMeasurement | undefined =
      this.measurementItem(row);
    if (measurement && measurement.attributes) {
      return dayjs
        .utc(measurement.attributes.created_at)
        .local()
        .format('D MMM YYYY');
    }
    return 'Never';
  }

  lastSentTime(row: CopdHomeMonitoringProgramRow): string {
    const measurement: CopdProgramMeasurement | undefined =
      this.measurementItem(row);
    if (measurement && measurement.attributes) {
      return dayjs
        .utc(measurement.attributes.created_at)
        .local()
        .format('h:mma');
    }
    return '—';
  }

  measurementItem(
    row: CopdHomeMonitoringProgramRow
  ): CopdProgramMeasurement | undefined {
    return this.measurements.find(
      (measurement) =>
        measurement.id === row.relationships.latest_measurement?.id
    );
  }

  reviewItem(row: CopdHomeMonitoringProgramRow): CopdProgramReview | undefined {
    return this.reviews.find(
      (review) => review.id === row.relationships.discharged_review?.id
    );
  }

  status(row: CopdHomeMonitoringProgramRow) {
    if (row.relationships.discharged_review) {
      return 'discharged';
    }

    const measurement: CopdProgramMeasurement | undefined =
      this.measurementItem(row);

    if (
      measurement &&
      measurement.relationships &&
      measurement.relationships.review === null
    ) {
      return 'ready-for-review';
    }

    return 'awaiting-measurements';
  }

  programLink(row: CopdHomeMonitoringProgramRow) {
    return {
      name: 'patient-virtual-ward',
      params: {
        patientId: row.relationships.patient?.id,
        programId: row.id,
        organisationId: this.organisationId
      }
    };
  }

  changePage(page: number) {
    this.$emit('changePage', page);
  }

  updateSort(sort: string) {
    this.$emit('changeSort', sort);
  }
}
