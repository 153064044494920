
import { Vue, Options } from 'vue-class-component';
import { IRadioJammerOption } from '@/lib';
import { BaseButton } from '@/lib/components/Button';
import BaseModal from './BaseModal.vue';
import { useHistoryStore } from '@/stores/history.store';

@Options({
  props: {
    title: {
      type: String,
      required: true
    },

    body: {
      type: String,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    next: {
      type: Function,
      required: true
    },

    back: {
      type: Function,
      required: true
    },
    skip: {
      type: Function,
      default: () => ({})
    }
  },
  components: {
    BaseButton,
    BaseModal
  }
})
export default class BaseRadioJammer extends Vue {
  options!: IRadioJammerOption[];
  back!: () => void;
  next!: () => void;
  skip!: () => void;
  historyStore = useHistoryStore();

  get leftOptions(): IRadioJammerOption[] {
    return this.options.filter((option) => option.position === 'left');
  }

  get rightOptions(): IRadioJammerOption[] {
    return this.options.filter((option) => option.position === 'right');
  }

  click(option: IRadioJammerOption) {
    this.$emit('input', option.label ? option.value : option);
    if (option.function === 'back') {
      this.back();
    } else if (option.function === 'next') {
      this.next();
    } else if (option.function === 'skip') {
      this.skip();
    } else if (option.function === 'exit') {
      this.$emit('change');
      this.cancel();
    }
  }

  cancel() {
    this.$router.push({
      name: this.historyStore.from ?? 'patient',
      params: this.$route.params
    });
  }
}
